import { createResource, defaultHeaders } from 'redux-rest-resource';

import { REACT_APP_API_URL } from '../constants/main';
import { getAuthData } from '../helpers/authStorage';

const setAuthHeader = (accessToken) => {
  Object.assign(defaultHeaders, {
    Authorization: accessToken,
  });
};

export const { types, actions, rootReducer } = createResource({
  name: 'highlight',
  url: `${REACT_APP_API_URL}/highlights/:id`,

  actions: {
    fetch: {
      url: `${REACT_APP_API_URL}/highlights`,
    },
  },
});

setAuthHeader(getAuthData().accessToken);
