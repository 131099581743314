import React, { useState } from 'react';

import classnames from 'classnames';

import Dropdown from 'react-bootstrap/Dropdown';
import classes from './styles.module.scss';

const CustomToggle = React.forwardRef(({ onClick }, ref) => (
  <div
    className={classes.dropdownToggle}
    ref={ref}
    onClick={(e) => {
      e.preventDefault();
      onClick(e);
    }}
  />
));

const CustomMenu = React.forwardRef(
  ({ children, style, className, 'aria-labelledby': labeledBy }, ref) => {
    const [value] = useState('');

    return (
      <div
        ref={ref}
        style={style}
        className={classnames(className, classes.dropDownMenu)}
        aria-labelledby={labeledBy}
      >
        <ul className="list-unstyled">
          {React.Children.toArray(children).filter(
            (child) =>
              !value || child.props.children.toLowerCase().startsWith(value)
          )}
        </ul>
      </div>
    );
  }
);

export default function CommentMenu({ onDelete, onEdit }) {
  return (
    <Dropdown onClick={(event) => event.stopPropagation()}>
      <Dropdown.Toggle as={CustomToggle} id="dropdown-custom-components">
        Custom toggle
      </Dropdown.Toggle>

      <Dropdown.Menu as={CustomMenu}>
        {onEdit && (
          <Dropdown.Item onClick={onEdit}>
            <span className={classes.edit}>Edit</span>
          </Dropdown.Item>
        )}
        <Dropdown.Item onClick={onDelete}>
          <span className={classes.delete}>Delete</span>
        </Dropdown.Item>
      </Dropdown.Menu>
    </Dropdown>
  );
}
