import React, { useState, useContext } from 'react';

import classnames from 'classnames';

import { UiContext } from '../../context/index';

import CreateFolderModal from '../../modals/CreateFolderModal';
import FileUploadModal from '../../modals/FileUploadModal';
import classes from './styles.module.scss';

export default function SideBar() {
  const [
    isSideBarExpanded,
    setIsSideBarExpanded,
    ,
    ,
    isSharedWithMeTabActive,
    setIsSharedWithMeTabActive,
  ] = useContext(UiContext);
  const [isCreateFolderModalVisible, setIsCreateFolderModalVisible] = useState(
    false
  );
  const [isFileUploadModalVisible, setIsFileUploadModalVisible] = useState(
    false
  );

  const toggleSideBar = () => {
    setIsSideBarExpanded((prevState) => !prevState);
  };

  const showCreateFolderModal = () => {
    setIsCreateFolderModalVisible(true);
  };

  const hideCreateFolderModal = () => {
    setIsCreateFolderModalVisible(false);
  };

  const showFileUploadModal = () => {
    setIsFileUploadModalVisible(true);
  };

  const hideFileUploadModal = () => {
    setIsFileUploadModalVisible(false);
  };

  /*  const toggleSharedWithMeTab = () => {
    setIsSharedWithMeTabActive((prevState) => !prevState);
  };
 */
  const enableSahredWithMeTab = () => {
    setIsSharedWithMeTabActive(true);
  };

  const enableMyLabTab = () => {
    setIsSharedWithMeTabActive(false);
  };

  /* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
  return (
    <div
      className={classnames(
        classes.SideBar,
        isSideBarExpanded && classes.expanded
      )}
    >
      <i className={classes.expandIcon} onClick={toggleSideBar}>
        Expand
      </i>
      <ul>
        <li
          className={classes.addResearch}
          onClick={() => {
            setIsSharedWithMeTabActive(false);
            showFileUploadModal();
          }}
        >
          Add research
        </li>
        <li
          className={classes.addDirectory}
          onClick={() => {
            setIsSharedWithMeTabActive(false);
            showCreateFolderModal();
          }}
        >
          Add directory
        </li>
        <li
          className={classnames(
            classes.myLab,
            !isSharedWithMeTabActive && classes.active
          )}
          onClick={enableMyLabTab}
        >
          My Lab
        </li>
        <li
          className={classnames(
            classes.shared,
            isSharedWithMeTabActive && classes.active
          )}
          onClick={enableSahredWithMeTab}
        >
          Shared with me
        </li>
      </ul>
      <footer className={classes.footer}>
        <i>Support</i>
        <span>Support</span>
      </footer>
      <CreateFolderModal
        show={isCreateFolderModalVisible}
        onHide={hideCreateFolderModal}
      />
      <FileUploadModal
        show={isFileUploadModalVisible}
        onHide={hideFileUploadModal}
      />
    </div>
  );
}
