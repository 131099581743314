/* eslint-disable consistent-return */
import React, { useState, useRef, useEffect, useContext } from 'react';

import axios from 'axios';
import { useHistory } from 'react-router-dom';
import { connect } from 'react-redux';
import classnames from 'classnames';

import { UiContext } from '../../context/index';
import { REACT_APP_API_URL } from '../../constants/main';
import { actions as usersActions } from '../../resources/users';
import useOnClickOutside from '../../helpers/useOnClickOutside';
import logo from '../../assets/images/logo.svg';
import classes from './styles.module.scss';

let timer;

function Header({ logoutUser, setSearchResults, searchResults }) {
  const [isUserMenuVisible, setIsUserMenuVisible] = useState(false);
  const [searchQuery, setSearchQuery] = useState('');
  const [searchSuggestions, setSearchSuggestions] = useState([]);

  const userMenuRef = useRef();
  const history = useHistory();
  const searchSuggestionsRef = useRef();

  const [, , , , isSharedWithMeTabActive] = useContext(UiContext);

  const toggleUserMenu = () => {
    setIsUserMenuVisible((prevState) => !prevState);
  };

  const hideUserMenu = () => {
    setIsUserMenuVisible(false);
  };

  const search = () => {
    if (searchQuery.trim() === '') {
      return setSearchResults(['empty']);
    }
    axios({
      method: 'GET',
      url: isSharedWithMeTabActive
        ? `${REACT_APP_API_URL}/share/search`
        : `${REACT_APP_API_URL}/folders/content/search`,
      params: {
        search: searchQuery,
      },
      headers: {
        authorization: `${
          JSON.parse(localStorage.getItem('authData')).accessToken
        }`,
      },
    })
      .then((response) => {
        setSearchResults(response.data.data);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const getSearchSuggestions = () => {
    clearTimeout(timer);
    if (searchQuery.trim() === '') {
      return setSearchSuggestions([]);
    }
    timer = setTimeout(() => {
      axios({
        method: 'GET',
        url: isSharedWithMeTabActive
          ? `${REACT_APP_API_URL}/share/search`
          : `${REACT_APP_API_URL}/folders/content/search`,
        params: {
          search: searchQuery,
        },
        headers: {
          authorization: `${
            JSON.parse(localStorage.getItem('authData')).accessToken
          }`,
        },
      })
        .then((response) => {
          setSearchSuggestions(response.data.data);
        })
        .catch((error) => {
          console.log(error);
        });
    }, 750);
  };

  useOnClickOutside(userMenuRef, hideUserMenu);
  useOnClickOutside(searchSuggestionsRef, () => {
    setSearchSuggestions([]);
  });

  useEffect(() => {
    if (searchQuery.trim() === '') {
      setSearchResults(['empty']);
    }
  }, [searchQuery]);

  useEffect(() => {
    if (searchResults[0] === 'empty') {
      setSearchQuery('');
    }
  }, [searchResults]);

  useEffect(() => {
    setSearchResults(['empty']);
    setSearchSuggestions([]);
  }, [isSharedWithMeTabActive]);

  /* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
  return (
    <header className={classes.Header}>
      <img className={classes.logo} src={logo} alt="Logo" />
      <form
        onSubmit={(event) => {
          event.preventDefault();
          search();
        }}
      >
        {!!searchSuggestions.length && (
          <div className={classes.searchSuggestions} ref={searchSuggestionsRef}>
            {searchSuggestions.map((suggestion) => {
              return (
                <div
                  key={suggestion.id}
                  className={classnames(
                    classes.searchSuggestion,
                    suggestion.type ? classes.file : classes.folder
                  )}
                  onClick={() => {
                    const entityTypeQueryString = suggestion.type
                      ? 'openFileFromSearch'
                      : 'openFolderFromSearch';
                    setSearchSuggestions([]);
                    // setSearchQuery('');
                    setSearchResults([suggestion]);
                    history.push(
                      `/home?${entityTypeQueryString}=${suggestion.id}`
                    );
                  }}
                >
                  <i>Element icon</i>
                  <span>{suggestion.name}</span>
                </div>
              );
            })}
          </div>
        )}

        <input
          value={searchQuery}
          onKeyUp={getSearchSuggestions}
          onChange={(event) => {
            setSearchQuery(event.target.value);
          }}
          className={classes.search}
          type="text"
          placeholder="Search"
        />
      </form>

      <i className={classes.help}>Help</i>
      <div
        className={classes.userMenu}
        onClick={toggleUserMenu}
        ref={userMenuRef}
      >
        <div
          className={classnames(
            classes.userMenuContent,
            isUserMenuVisible && classes.visible
          )}
        >
          <ul>
            <li
              onClick={() => {
                logoutUser();
                history.push('/login');
              }}
            >
              Log out
            </li>
          </ul>
        </div>
      </div>
    </header>
  );
}

export default connect(null, {
  logoutUser: usersActions.logoutUser,
})(Header);
