import { createResource, defaultHeaders } from 'redux-rest-resource';

import { REACT_APP_API_URL } from '../constants/main';
// import { paginatedFetchReducer } from '../helpers/resourceReducers';
import { getAuthData } from '../helpers/authStorage';

const setAuthHeader = (accessToken) => {
  Object.assign(defaultHeaders, {
    Authorization: accessToken,
  });
};

export const { types, actions, rootReducer } = createResource({
  name: 'sharedEntity',
  url: `${REACT_APP_API_URL}/share/:id`,

  actions: {
    fetch: {
      url: `${REACT_APP_API_URL}/share`,
      // reduce: paginatedFetchReducer,
    },
  },
});

setAuthHeader(getAuthData().accessToken);
