/* eslint-disable jsx-a11y/media-has-caption */
import React, { useEffect, useRef, useState } from 'react';

import axios from 'axios';
import moment from 'moment';
import classnames from 'classnames';

import useOnClickOutside from '../../../helpers/useOnClickOutside';
import { REACT_APP_API_URL } from '../../../constants/main';

import classes from './styles.module.scss';

function bytesToSize(bytes) {
  const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB'];
  // eslint-disable-next-line eqeqeq
  if (bytes == 0) return '0 Byte';
  // eslint-disable-next-line radix
  const i = parseInt(Math.floor(Math.log(bytes) / Math.log(1024)));
  // eslint-disable-next-line no-restricted-properties
  return `${Math.round(bytes / Math.pow(1024, i), 2)} ${sizes[i]}`;
}

export default function FileDetails({ show, onHide, file }) {
  const [fileSize, setFileSize] = useState('');
  const [fileUrl, setFileUrl] = useState('');

  const FileDetailsRef = useRef();
  const videoRef = useRef();

  const getFileSize = () => {
    console.log('GETTING FILE SIZE');
    axios({
      method: 'HEAD',
      url: fileUrl,
      headers: { 'Access-Control-Allow-Origin': '*' },
    })
      .then((response) => {
        setFileSize(bytesToSize(response.headers['content-length']));
      })
      .catch((error) => console.log(error));
  };

  useEffect(() => {
    if (file) {
      axios
        .get(`${REACT_APP_API_URL}/files/getUrl?key=${file.path}`, {
          headers: {
            authorization: `${
              JSON.parse(localStorage.getItem('authData')).accessToken
            }`,
          },
        })
        .then((response) => {
          console.log(response);
          setFileUrl(response.data.url);
        })
        .catch((error) => {
          console.log(error);
        });
    }
  }, [file]);

  useEffect(() => {
    if (videoRef.current) {
      videoRef.current.load();
    }
    if (fileUrl) {
      getFileSize();
    }
  }, [fileUrl]);

  const closeFileDetailsSideBar = () => {
    videoRef.current.pause();
    onHide();
  };

  useOnClickOutside(FileDetailsRef, closeFileDetailsSideBar);

  return (
    <div
      className={classnames(classes.FileDetails, show && classes.show)}
      ref={FileDetailsRef}
    >
      <header>
        <h1>{file ? file.name : ''}</h1>
        <i onClick={closeFileDetailsSideBar}>Close</i>
      </header>
      <div className={classes.preview}>
        <video controls ref={videoRef}>
          <source src={fileUrl} />
        </video>
      </div>
      <div className={classes.details}>
        <div>
          <span>Creation date</span>
          <span>{file ? moment(file.createdAt).format('DD.MM.YYYY') : ''}</span>
        </div>
        <div>
          <span>Size</span>
          <span>{fileSize}</span>
        </div>
        <div>
          <span>Last comment date</span>
          <span>{file ? moment(file.updatedAt).format('DD.MM.YYYY') : ''}</span>
        </div>
        <div>
          <span>Last open date</span>
          <span>{file ? moment(file.updatedAt).format('DD.MM.YYYY') : ''}</span>
        </div>
      </div>
    </div>
  );
}
