/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
import React, { useRef, useState, useEffect, useContext } from 'react';

import { connect } from 'react-redux';
import classnames from 'classnames';

import { actions } from '../../../../../resources/highlights';
// eslint-disable-next-line import/no-named-as-default
// eslint-disable-next-line import/no-named-as-default-member
import CreateHighlight from '../CreateHighlight';
import Highlight from '../Highlight';

import { VideoContext, HighlightContext } from '../../../../../context/index';
import isOverlapping from '../../../../../helpers/isOverlapping';
import { HIGHLIGHT_COLORS } from '../../../../../constants/main';
import useClickAndHighlight from '../../../../../helpers/useClickAndHighlight';
import classes from './styles.module.scss';

function LineChart({
  title,
  secondsToPixelsRatio,
  videoDuration,
  redLine,
  highlightColor,
  setHighlightColor,
  video,
  fetchHighlights,
  highlights,
  areHighlightsFetching,
  setRef,
  presentationHighlights,
  isSharedVideoMode,
  chartData,
}) {
  const [actualData, setActualData] = useState([]);
  const [lines, setLines] = useState([]);
  const [isSelectable, setIsSelectable] = useState(false);
  const [selectionStartPosition, setSelectionStartPosition] = useState(0);
  const [selectionWidth, setSelectionWidth] = useState(0);
  const [selectionLeft, setSelectionLeft] = useState(0);
  const [chartDataElementsPerSecond, setChartDataElementsPerSecond] = useState(
    8
  );

  const [, , isPresentationMode] = useContext(VideoContext);
  const [, , , , , , isAddHighlightModeActive] = useContext(HighlightContext);

  // const chartRef = useRef();
  const highLightRef = useRef();

  const deleteSelection = () => {
    setSelectionWidth(0);
    document.body.classList.remove('moveListener');
  };

  const finishSelection = () => {
    setIsSelectable(false);
    document.body.classList.remove('moveListener');
    if (
      isOverlapping(
        highLightRef,
        document.querySelectorAll('[ishighlight="true"]')
      )
    ) {
      deleteSelection();
    }
  };

  const { xPosition } = useClickAndHighlight(setRef, finishSelection);

  const getClickXPosition = (pageX) => {
    const coords = setRef.current.getBoundingClientRect();
    return pageX - coords.left + setRef.current.scrollLeft;
  };

  useEffect(() => {
    fetchHighlights(null, { query: { fileId: video.id } });
  }, []);

  useEffect(() => {
    if (!isSelectable) {
      return;
    }

    if (selectionStartPosition > videoDuration * secondsToPixelsRatio + 8) {
      // setRef.current.click();
      document.body.classList.remove('moveListener');
      document.body.click();
      return;
    }

    if (selectionStartPosition < xPosition) {
      setSelectionLeft(selectionStartPosition);
      let width =
        selectionStartPosition < xPosition
          ? xPosition - selectionStartPosition
          : 0;
      if (xPosition > videoDuration * secondsToPixelsRatio + 8) {
        width =
          videoDuration * secondsToPixelsRatio + 8 - selectionStartPosition;
      }
      setSelectionWidth(width);
    } else if (selectionStartPosition > xPosition && xPosition !== 0) {
      setSelectionLeft(xPosition);
      setSelectionWidth(selectionStartPosition - xPosition);
    }
  }, [xPosition, selectionStartPosition, isSelectable]);

  const startSelection = (pageX) => {
    if (document.body.classList.contains('moveListener')) {
      return;
    }
    document.body.classList.add('moveListener');
    setIsSelectable(true);
    setSelectionWidth(0);
    setSelectionStartPosition(getClickXPosition(pageX));
  };

  useEffect(() => {
    if (videoDuration) {
      /* const data = [];
      for (let i = 0; i < videoDuration * 8; i += 1) {
        data.push(Math.ceil(Math.random() * 100));
      } */
      if (chartData) {
        setActualData(chartData);
        setChartDataElementsPerSecond(chartData.length / videoDuration);
      }
    }
  }, [videoDuration, chartData]);

  useEffect(() => {
    if (actualData && secondsToPixelsRatio) {
      const linesArr = [];

      for (let i = 0; i < actualData.length - 1; i += 1) {
        linesArr.push(
          <line
            chart="true"
            key={i}
            x1={i * (secondsToPixelsRatio / chartDataElementsPerSecond)}
            y1={42 - 42 * (actualData[i] / 100)}
            x2={(i + 1) * (secondsToPixelsRatio / chartDataElementsPerSecond)}
            y2={42 - 42 * (actualData[i + 1] / 100)}
            style={{ stroke: 'rgb(70, 116, 193)', strokeWidth: 1 }}
          />
        );
      }
      setLines(linesArr);
    }
  }, [actualData, secondsToPixelsRatio]);

  return (
    <div
      className={classes.LineChart}
      ref={setRef}
      id={title.split(' ').join('')}
      // style={{
      //   paddingBottom:
      //     setRef.current &&
      //     setRef.current.scrollWidth > setRef.current.clientWidth
      //       ? 95
      //       : 75,
      // }}
    >
      <header className={classes.chartHeader}>
        <h1>{title}</h1>
      </header>
      <main
        className={classnames(
          classes.chartMain,
          isAddHighlightModeActive && classes.selectionActive
        )}
      >
        <div className={classes.axis}>
          <span>100</span>
          <span>80</span>
          <span>60</span>
          <span>40</span>
          <span>20</span>
          <span>0</span>
        </div>
        <div
          onClick={(event) => {
            if (
              isPresentationMode ||
              isSharedVideoMode ||
              !isAddHighlightModeActive
            ) {
              return;
            }

            if (!isSelectable) {
              startSelection(event.pageX);
            }
          }}
          className={classes.chart}
          style={{
            minWidth: videoDuration * secondsToPixelsRatio,
          }}
        >
          <div className={classes.background}>
            {redLine && <div className={classes.redLine} />}
            <div className={classes.grayLine} />
            <div className={classes.grayLine} />
            <div className={classes.grayLine} />
            <div className={classes.grayLine} />
          </div>
          <div className={classes.data}>
            <svg
              chart="true"
              width="100%"
              height="42"
              style={{
                position: 'relative',
                zIndex: '1',
                top: 1,
                transform: 'scaleY(1.1)',
              }}
            >
              {lines}
            </svg>
          </div>
        </div>
      </main>
      <div
        className={classnames(
          classes.selection,
          selectionWidth > 0 && !isSelectable && classes.selected
        )}
        style={{
          left: selectionLeft,
          width: selectionWidth,
          backgroundColor: HIGHLIGHT_COLORS[highlightColor.toUpperCase()][1],
          borderBottom: `1px solid ${
            HIGHLIGHT_COLORS[highlightColor.toUpperCase()][0]
          }`,
        }}
        ref={highLightRef}
      >
        <CreateHighlight
          selectionWidth={selectionWidth}
          selectionRef={highLightRef}
          show={selectionWidth > 0 && !isSelectable}
          currentColor={highlightColor}
          setCurrentColor={setHighlightColor}
          deleteSelection={deleteSelection}
          selectionLeft={selectionLeft}
          secondsToPixelsRatio={secondsToPixelsRatio}
          video={video}
          chartTitle={title}
        />
      </div>
      <div className={classes.highlights}>
        {!presentationHighlights &&
          highlights &&
          !areHighlightsFetching &&
          highlights.map((highlight) => {
            if (highlight.chart !== title) {
              return null;
            }
            return (
              <div
                key={highlight.id}
                className={classes.highlight}
                style={{ left: highlight.startTime * secondsToPixelsRatio }}
              >
                <Highlight
                  highlight={highlight}
                  secondsToPixelsRatio={secondsToPixelsRatio}
                />
              </div>
            );
          })}
        {presentationHighlights &&
          presentationHighlights.map((highlight) => {
            if (highlight.chart !== title) {
              return null;
            }
            return (
              <div
                key={highlight.id}
                className={classes.highlight}
                style={{ left: highlight.startTime * secondsToPixelsRatio }}
              >
                <Highlight
                  highlight={highlight}
                  secondsToPixelsRatio={secondsToPixelsRatio}
                />
              </div>
            );
          })}
      </div>
    </div>
  );
}

export default connect(
  (state) => ({
    highlights: state.highlights.items,
    areHighlightsFetching: state.highlights.isFetching,
  }),
  {
    fetchHighlights: actions.fetchHighlights,
  }
)(LineChart);
