import React from 'react';

import logo from '../../assets/images/logo.svg';
import classes from './styles.module.scss';

export default function EmptyHeader() {
  return (
    <header className={classes.EmptyHeader}>
      <img src={logo} alt="Logo" className={classes.logo} />
    </header>
  );
}
