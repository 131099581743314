/* eslint-disable radix */
import React, { useState, useEffect, useRef, useContext } from 'react';

import Slider from 'react-custom-slider';

import { VideoContext } from '../../../../../context/index';
// import { useLocation } from 'react-router-dom';

import classes from './styles.module.scss';

export default function ScrollBar({
  climaxChartRef,
  imageFlowChartRef,
  clutteringChartRef,
  soundIntencityRef,
  flickeringChartRef,
  sceneLineRef,
  secondsToPixelsRatio,
}) {
  const [scroll, setScroll] = useState(0);
  const [thumbSize, setThumbSize] = useState(0);

  const scrollBarRef = useRef();

  // const query = new URLSearchParams(useLocation().search);

  const [, videoRef, isPresentationMode] = useContext(VideoContext);

  useEffect(() => {
    climaxChartRef.current.scroll({
      left:
        (climaxChartRef.current.scrollWidth -
          climaxChartRef.current.clientWidth) *
        (scroll / 100),
    });
    imageFlowChartRef.current.scroll({
      left:
        (imageFlowChartRef.current.scrollWidth -
          imageFlowChartRef.current.clientWidth) *
        (scroll / 100),
    });
    clutteringChartRef.current.scroll({
      left:
        (clutteringChartRef.current.scrollWidth -
          clutteringChartRef.current.clientWidth) *
        (scroll / 100),
    });
    soundIntencityRef.current.scroll({
      left:
        (soundIntencityRef.current.scrollWidth -
          soundIntencityRef.current.clientWidth) *
        (scroll / 100),
    });
    flickeringChartRef.current.scroll({
      left:
        (flickeringChartRef.current.scrollWidth -
          flickeringChartRef.current.clientWidth) *
        (scroll / 100),
    });
    sceneLineRef.current.scroll({
      left:
        (sceneLineRef.current.scrollWidth - sceneLineRef.current.clientWidth) *
        (scroll / 100),
    });
  }, [scroll]);

  useEffect(() => {
    if (
      videoRef?.current?.currentTime * secondsToPixelsRatio >
        sceneLineRef.current.clientWidth + sceneLineRef.current.scrollLeft &&
      !videoRef.current.paused
    ) {
      setScroll((prevState) => {
        let newScrollValue =
          prevState +
          sceneLineRef.current.clientWidth /
            ((sceneLineRef.current.scrollWidth -
              sceneLineRef.current.clientWidth) /
              100);

        if (newScrollValue > 100) {
          newScrollValue = 100;
        }

        return newScrollValue;
      });
    }
  }, [videoRef?.current?.currentTime]);

  useEffect(() => {
    const visibleArea =
      (climaxChartRef.current.clientWidth /
        climaxChartRef.current.scrollWidth) *
      scrollBarRef.current.clientWidth;
    scrollBarRef.current.style.setProperty('--Width', `${visibleArea}%`);
    setThumbSize(visibleArea);
  }, [secondsToPixelsRatio]);

  return (
    <div
      className={classes.ScrollBar}
      ref={scrollBarRef}
      style={{ display: isPresentationMode ? 'none' : 'block' }}
    >
      <Slider
        value={scroll}
        showMarkers={false}
        trackLength={scrollBarRef?.current?.clientWidth - thumbSize}
        onChange={(value) => setScroll(value)}
        handlerWidth={thumbSize}
        handlerHeight={7}
        handlerActiveColor="gray"
        trackThickness={0}
        handlerColor="gray"
        grabCursor={false}
        handlerStyle={{ opacity: 0.9, border: 'none' }}
      />
    </div>
  );
}
