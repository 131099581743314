import React, { useContext, useRef } from 'react';

// eslint-disable-next-line import/no-named-as-default
// eslint-disable-next-line import/no-named-as-default-member
import LineChart from './LineChart';
import PixelatedBarChart from './PixelatedBarChart';
import BarChart from './BarChart';
import ScrollBar from './ScrollBar';
import { UiContext } from '../../../../context/index';

import classes from './styles.module.scss';

export default function MainCharts({
  secondsToPixelsRatio,
  videoDuration,
  highlightColor,
  setHighlightColor,
  video,
  sceneLineRef,
  presentationHighlights,
  isSharedVideoMode,
}) {
  const [, , , , , , , setMainChartsRef] = useContext(UiContext);

  const mainChartsRef = useRef();
  setMainChartsRef(mainChartsRef);

  const climaxChartRef = useRef();
  const imageFlowChartRef = useRef();
  const clutteringChartRef = useRef();
  const soundIntencityRef = useRef();
  const flickeringChartRef = useRef();

  return (
    <div className={classes.MainCharts} ref={mainChartsRef}>
      <LineChart
        secondsToPixelsRatio={secondsToPixelsRatio}
        videoDuration={videoDuration}
        title="Climax"
        highlightColor={highlightColor}
        setHighlightColor={setHighlightColor}
        video={video}
        chartData={video.climax}
        redLine
        setRef={climaxChartRef}
        presentationHighlights={presentationHighlights}
        isSharedVideoMode={isSharedVideoMode}
      />
      <LineChart
        secondsToPixelsRatio={secondsToPixelsRatio}
        videoDuration={videoDuration}
        highlightColor={highlightColor}
        setHighlightColor={setHighlightColor}
        video={video}
        chartData={video.imageFlow}
        title="Image flow"
        setRef={imageFlowChartRef}
        presentationHighlights={presentationHighlights}
        isSharedVideoMode={isSharedVideoMode}
      />
      <PixelatedBarChart
        title="Sound intencity"
        secondsToPixelsRatio={secondsToPixelsRatio}
        videoDuration={videoDuration}
        highlightColor={highlightColor}
        setHighlightColor={setHighlightColor}
        video={video}
        setRef={soundIntencityRef}
        chartData={video.soundIntencity}
        presentationHighlights={presentationHighlights}
        isSharedVideoMode={isSharedVideoMode}
      />
      <BarChart
        title="Flickering"
        secondsToPixelsRatio={secondsToPixelsRatio}
        videoDuration={videoDuration}
        highlightColor={highlightColor}
        setHighlightColor={setHighlightColor}
        video={video}
        setRef={flickeringChartRef}
        chartData={video.flickering}
        presentationHighlights={presentationHighlights}
        isSharedVideoMode={isSharedVideoMode}
      />
      <LineChart
        secondsToPixelsRatio={secondsToPixelsRatio}
        videoDuration={videoDuration}
        highlightColor={highlightColor}
        setHighlightColor={setHighlightColor}
        video={video}
        title="Cluttering"
        setRef={clutteringChartRef}
        chartData={video.cluttering}
        presentationHighlights={presentationHighlights}
        isSharedVideoMode={isSharedVideoMode}
      />
      <div className={classes.scrollBarContainer}>
        <ScrollBar
          clutteringChartRef={clutteringChartRef}
          imageFlowChartRef={imageFlowChartRef}
          climaxChartRef={climaxChartRef}
          soundIntencityRef={soundIntencityRef}
          flickeringChartRef={flickeringChartRef}
          secondsToPixelsRatio={secondsToPixelsRatio}
          sceneLineRef={sceneLineRef}
        />
      </div>
    </div>
  );
}
