import React from 'react';

import FiltersComponent from '../../../components/FileManager/EditFile/Filters';

import classes from './styles.module.scss';

export default function Filters({ video }) {
  return (
    <div className={classes.Filters}>
      <div>
        <FiltersComponent video={video} isDisabled />
      </div>
    </div>
  );
}
