import React, { useState, useContext } from 'react';

import classnames from 'classnames';

import { FoldersContext, UiContext } from '../../context';

import Header from '../Header';
import SideBar from '../SideBar';
// eslint-disable-next-line import/no-named-as-default
import HighlightMenu from './HighlightMenu';
import Spinner from '../Spinner';

import classes from './styles.module.scss';

export default function Layout({
  children,
  searchResults,
  setSearchResults,
  isHighlightMenuVisible,
  currentHighlight,
  highlightRef,
  hidden,
}) {
  const [currentOpenFolder, setCurrentOpenFolder] = useState(null);

  const [isSideBarExpanded] = useContext(UiContext);

  return (
    <FoldersContext.Provider value={[currentOpenFolder, setCurrentOpenFolder]}>
      <div
        className={classnames(
          classes.Layout,
          isSideBarExpanded && classes.wideSideBar
        )}
      >
        {!hidden && (
          <>
            <Header
              searchResults={searchResults}
              setSearchResults={setSearchResults}
            />
            <SideBar />
          </>
        )}
        <div className={classnames(classes.content, hidden && classes.hidden)}>
          {children}
        </div>
        <HighlightMenu
          isHighlightMenuVisible={isHighlightMenuVisible}
          currentHighlight={currentHighlight}
          highlightRef={highlightRef}
        />

        <Spinner />
      </div>
    </FoldersContext.Provider>
    // </UiContext.Provider>
  );
}
