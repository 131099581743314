import React, { useContext } from 'react';

import { VideoContext } from '../../../../context/index';
import CircleChart from './CircleChart';
import PieChart from './PieChart';
import GenderChart from './GenderChart';

import classes from './styles.module.scss';

// import classnames from 'classnames';

export default function PercentageGraphs({ video }) {
  const [, , isPresentationMode] = useContext(VideoContext);

  return (
    <div className={classes.PercentageGraphs}>
      <div className={classes.radialCharts}>
        <div className={classes.chart}>
          <CircleChart
            percentage={(video.flickerTotalRate * 100).toFixed()}
            title="Flicker"
          />
        </div>
        <div className={classes.chart}>
          <CircleChart
            percentage={(video.motionTotalRate * 100).toFixed()}
            title="Motion"
          />
        </div>
        <div className={classes.chart}>
          <CircleChart
            percentage={(video.clearTotalRate * 100).toFixed()}
            title="Clear"
          />
        </div>
        {isPresentationMode && (
          <div className={classes.chart}>
            <GenderChart />
          </div>
        )}
      </div>
      {!isPresentationMode && (
        <div className={classes.pieCharts}>
          <div className={classes.firstPieChart}>
            <PieChart
              title="Screen Presence"
              women={(video.screenPresenceWomanTotalRate * 100).toFixed()}
              men={(video.screenPresenceManTotalRate * 100).toFixed()}
            />
          </div>
          <PieChart
            title="Speaking Presence"
            women={(video.speakingPresenceWomanTotalRate * 100).toFixed()}
            men={(video.speakingPresenceManTotalRate * 100).toFixed()}
          />
        </div>
      )}
    </div>
  );
}
