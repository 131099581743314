import { combineReducers } from 'redux';

import { rootReducer as users } from '../resources/users';
import { rootReducer as folders } from '../resources/folders';
import { rootReducer as files } from '../resources/files';
import { rootReducer as sharedEntities } from '../resources/sharedEntities';
import { rootReducer as comments } from '../resources/comments';
import { rootReducer as highlights } from '../resources/highlights';

const reducers = combineReducers({
  users,
  folders,
  files,
  sharedEntities,
  comments,
  highlights,
});

export default reducers;
