import { useEffect, useState } from 'react';

export default function useDraggable(
  draggableRef,
  setIsCursorAnimationDisabled
) {
  const [xPosition, setXPosition] = useState(0);

  const move = (event) => {
    if (setIsCursorAnimationDisabled) {
      setIsCursorAnimationDisabled(true);
    }
    setXPosition(event.clientX);
  };

  const onMouseUp = () => {
    if (setIsCursorAnimationDisabled) {
      setIsCursorAnimationDisabled(false);
    }
    document.body.removeEventListener('mousemove', move);
    document.body.removeEventListener('mouseup', onMouseUp);
  };

  const onMouseDown = (event) => {
    // event.stopPropagation();
    event.preventDefault();

    document.body.addEventListener('mousemove', move);
    document.body.addEventListener('mouseup', onMouseUp);
  };

  useEffect(() => {
    if (draggableRef.current) {
      draggableRef.current.addEventListener('mousedown', onMouseDown);
    }
    return () => {
      if (draggableRef.current) {
        document.body.removeEventListener('mousemove', move);
        document.body.removeEventListener('mouseup', onMouseUp);
        draggableRef.current.removeEventListener('mousedown', onMouseDown);
      }
    };
  }, []);

  return {
    xPosition,
  };
}
