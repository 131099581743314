import React, { useContext, useRef, useEffect } from 'react';

import { connect } from 'react-redux';
import * as Yup from 'yup';
import { Formik } from 'formik';
import Modal from 'react-bootstrap/Modal';

import { UiContext } from '../../context/index';
import { actions } from '../../resources/files';
import FormField from '../../components/Form/FormField';
import StyledButton from '../../components/Form/StyledButton';
import '../../assets/bootstrap-modal.css';
import classes from './styles.module.scss';

const ValidationSchema = Yup.object().shape({
  fileName: Yup.string().required('File name is a required field'),
});

function RenameFileModal({ show, onHide, updateFile, file }) {
  const [, , , setFolderContentElement] = useContext(UiContext);
  const inputRef = useRef();

  useEffect(() => {
    if (inputRef.current) {
      inputRef.current.focus();
    }
  }, [show]);

  return (
    <Modal
      show={show}
      onHide={onHide}
      size="md"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header closeButton className={classes.header}>
        Rename
      </Modal.Header>

      <Formik
        initialValues={{
          fileName: file ? file.name : '',
        }}
        validationSchema={ValidationSchema}
        onSubmit={(values, { setFieldError }) => {
          updateFile({ ...file, name: values.fileName })
            .then((response) => {
              setFieldError('fileName', response.statusText);
              setFolderContentElement({ element: null, status: 'moved' });
              onHide();
            })
            .catch((error) => {
              console.log(error);
              setFieldError(
                'fileName',
                error.body.message || 'Something went wrong'
              );
            });
        }}
      >
        {({
          values,
          errors,
          touched,
          handleChange,
          handleBlur,
          handleSubmit,
          // setFieldValue,
          // isSubmitting,
        }) => (
          <>
            <Modal.Body className={classes.body}>
              <form onSubmit={handleSubmit}>
                <FormField
                  label="File name"
                  onChange={handleChange('fileName')}
                  onBlur={handleBlur('fileName')}
                  value={values.fileName}
                  isInvalid={touched.fileName && errors.fileName}
                  errorMessage={errors.fileName}
                  setRef={inputRef}
                />
              </form>
            </Modal.Body>

            <Modal.Footer className={classes.footer}>
              <StyledButton
                className={classes.button}
                onClick={onHide}
                label="Cancel"
                variant="outline"
              />
              <StyledButton
                className={classes.button}
                onClick={handleSubmit}
                label="Ok"
              />
            </Modal.Footer>
          </>
        )}
      </Formik>
    </Modal>
  );
}

export default connect(null, {
  updateFile: actions.updateFile,
})(RenameFileModal);
