import React, { useState } from 'react';

import classnames from 'classnames';

import Dropdown from 'react-bootstrap/Dropdown';
import { REACT_APP_API_URL } from '../../../constants/main';
import classes from './styles.module.scss';

const CustomToggle = React.forwardRef(({ onClick, hidden }, ref) => (
  <div
    style={{ opacity: hidden ? 0 : 1, pointerEvents: hidden ? 'none' : 'auto' }}
    className={classes.dropdownToggle}
    ref={ref}
    onClick={(e) => {
      e.preventDefault();
      onClick(e);
    }}
  />
));

const CustomMenu = React.forwardRef(
  ({ children, style, className, 'aria-labelledby': labeledBy }, ref) => {
    const [value] = useState('');

    return (
      <div
        ref={ref}
        style={style}
        className={classnames(className, classes.dropDownMenu)}
        aria-labelledby={labeledBy}
      >
        <ul className="list-unstyled">
          {React.Children.toArray(children).filter(
            (child) =>
              !value || child.props.children.toLowerCase().startsWith(value)
          )}
        </ul>
      </div>
    );
  }
);

export default function DropDownMenu({
  hidden,
  onRename,
  onRemove,
  folderMenu,
  onOpen,
  onMove,
  onShare,
  file,
  shared,
  onFileDetails,
  onGetShareableLink,
}) {
  return (
    <Dropdown onClick={(event) => event.stopPropagation()}>
      <Dropdown.Toggle
        hidden={hidden}
        as={CustomToggle}
        id="dropdown-custom-components"
      >
        Custom toggle
      </Dropdown.Toggle>

      <Dropdown.Menu as={CustomMenu}>
        <Dropdown.Item onClick={onOpen}>
          <span className={classes.open}>Open</span>
        </Dropdown.Item>
        <Dropdown.Item
          onClick={onShare}
          style={{ display: shared ? 'none' : 'flex' }}
        >
          <span className={classes.share}>Share</span>
        </Dropdown.Item>
        <Dropdown.Item
          onClick={onMove}
          style={{ display: shared ? 'none' : 'flex' }}
        >
          <span className={classes.move}>Move to</span>
        </Dropdown.Item>
        <Dropdown.Item
          style={{ display: shared ? 'none' : 'flex' }}
          onClick={onGetShareableLink}
        >
          <span className={classes.get}>Get shareable link</span>
        </Dropdown.Item>
        <Dropdown.Item
          onClick={onRename}
          style={{ display: shared ? 'none' : 'flex' }}
        >
          <span className={classes.rename}>Rename</span>
        </Dropdown.Item>
        <Dropdown.Item
          onClick={onFileDetails}
          style={{ display: folderMenu ? 'none' : 'flex' }}
        >
          <span className={classes.details}>Details</span>
        </Dropdown.Item>
        {!folderMenu && (
          <Dropdown.Item
            href={`${REACT_APP_API_URL}/files/download/${
              file.id
            }?authorization=${
              JSON.parse(localStorage.getItem('authData')).accessToken
            }`}
          >
            <span className={classes.download}>Download</span>
          </Dropdown.Item>
        )}
        <Dropdown.Item
          onClick={onRemove}
          style={{ display: shared ? 'none' : 'flex' }}
        >
          <span className={classes.remove}>Remove</span>
        </Dropdown.Item>
      </Dropdown.Menu>
    </Dropdown>
  );
}
