import React, { useState } from 'react';

import { connect } from 'react-redux';
import { NavLink } from 'react-router-dom';
import * as Yup from 'yup';
import { Formik } from 'formik';

import EmptyHeader from '../../components/EmptyHeader';
import FormField from '../../components/Form/FormField';
import StyledButton from '../../components/Form/StyledButton';

import { actions } from '../../resources/users';
import checkSign from '../../assets/images/checkSign.svg';
import errorSign from '../../assets/images/errorSign.svg';
import classes from './styles.module.scss';

const ValidationSchema = Yup.object().shape({
  email: Yup.string()
    .email('Please enter a valid email')
    .required('Email is a required field'),
});

function ForgotPasswordPage({ forgotPasswordUser }) {
  const [isEmailCorrect, setIsEmailCorrect] = useState(null);
  const [isEmailSent, setIsEmailSent] = useState(false);

  let content;

  if (!isEmailSent) {
    content = (
      <div className={classes.passwordFormContainer}>
        <p className={classes.heading}>Forgot password?</p>
        <p className={classes.explanation}>
          Enter the email you use for Lookam, and we’ll help you create a new
          password.
        </p>
        <Formik
          initialValues={{
            email: '',
          }}
          validationSchema={ValidationSchema}
          onSubmit={(values) => {
            forgotPasswordUser(values)
              .then(() => {
                setIsEmailSent(true);
                setIsEmailCorrect(true);
              })
              .catch(() => {
                setIsEmailSent(true);
                setIsEmailCorrect(false);
              });
          }}
        >
          {({
            values,
            errors,
            touched,
            handleChange,
            handleBlur,
            handleSubmit,
            // setFieldValue,
            // isSubmitting,
          }) => (
            <form className={classes.passwordForm} onSubmit={handleSubmit}>
              <FormField
                label="Email address"
                onChange={handleChange('email')}
                onBlur={handleBlur('email')}
                value={values.email}
                isInvalid={touched.email && errors.email}
                errorMessage={errors.email}
              />
              <StyledButton
                label="Reset"
                style={{ alignSelf: 'center' }}
                onClick={handleSubmit}
              />
              <NavLink className={classes.goBack} to="/login">
                Go back to login
              </NavLink>
            </form>
          )}
        </Formik>
      </div>
    );
  } else {
    content = (
      <div className={classes.successMessage}>
        <img
          src={isEmailCorrect ? checkSign : errorSign}
          alt="Sign"
          className={classes.sign}
        />
        <p>
          {isEmailCorrect
            ? 'Your password was successfully changed.'
            : 'User with provided email is not registered.'}
        </p>
        <NavLink className={classes.homeLink} to="/home">
          Go home
        </NavLink>
      </div>
    );
  }

  return (
    <div className={classes.ForgotPasswordPage}>
      <EmptyHeader />
      {content}
    </div>
  );
}

export default connect(null, {
  forgotPasswordUser: actions.forgotPasswordUser,
})(ForgotPasswordPage);
