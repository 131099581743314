import React, { useCallback, useContext } from 'react';

import axios from 'axios';
import { useDropzone } from 'react-dropzone';
import Modal from 'react-bootstrap/Modal';

import { UiContext } from '../../context/index';
import { REACT_APP_API_URL } from '../../constants/main';
import '../../assets/bootstrap-modal.css';
import uploadFileIcon from '../../assets/images/uploadFileIcon.svg';
import classes from './styles.module.scss';

export default function FileUploadModal({
  show,
  onHide,
  folder,
  uploadLogo,
  uploadWatermark,
  fileId,
}) {
  const [
    ,
    ,
    ,
    setFolderContentElement,
    ,
    ,
    ,
    ,
    ,
    setIsSpinnerVisible,
  ] = useContext(UiContext);

  let message = 'Drag&Drop files here';
  if (uploadLogo) {
    message = (
      <span>
        Drag&Drop your <span className={classes.logo}>LOGO</span> here
      </span>
    );
  } else if (uploadWatermark) {
    message = 'Drag&Drop your watermark here';
  }

  const onDrop = useCallback((acceptedFiles) => {
    let path = `${REACT_APP_API_URL}/files`;

    const formData = new FormData();
    formData.append('file', acceptedFiles[0]);
    if (folder) {
      formData.append('folderId', folder.id);
    }
    if (uploadLogo) {
      formData.append('fileId', fileId);
      path = `${REACT_APP_API_URL}/files/uploadLogo`;
    }
    if (uploadWatermark) {
      formData.append('fileId', fileId);
      path = `${REACT_APP_API_URL}/files/uploadWatermark`;
    }
    onHide();
    setIsSpinnerVisible(true);
    axios
      .post(path, formData, {
        headers: {
          authorization: `${
            JSON.parse(localStorage.getItem('authData')).accessToken
          }`,
        },
      })
      .then((response) => {
        setFolderContentElement({ element: response.data, status: 'added' });
        setIsSpinnerVisible(false);
        // onHide();
      })
      .catch((error) => {
        setIsSpinnerVisible(false);
        console.log(error);
      });
  });

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    accept: uploadLogo || uploadWatermark ? 'image/*' : 'video/*',
  });
  return (
    <Modal
      show={show}
      onHide={onHide}
      dialogClassName={classes.FileUploadModal}
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Body className={classes.modalBody}>
        <div {...getRootProps()} className={classes.uploader}>
          <input {...getInputProps()} />
          {isDragActive ? (
            <p>Drop the files here ...</p>
          ) : (
            <>
              <img src={uploadFileIcon} alt="Upload file icon" />
              <p className={classes.firstLine}>{message}</p>
              <p className={classes.secondLine}>or</p>
              <div className={classes.browseButton}>Browse Files</div>
            </>
          )}
        </div>
      </Modal.Body>
    </Modal>
  );
}
