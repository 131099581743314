import React, { useRef, useEffect } from 'react';

import Modal from 'react-bootstrap/Modal';

import StyledButton from '../../components/Form/StyledButton';
import '../../assets/bootstrap-modal.css';
import classes from './styles.module.scss';

export default function ErrorMessageModal({ show, onHide, message }) {
  const inputRef = useRef();

  useEffect(() => {
    if (inputRef.current) {
      inputRef.current.focus();
    }
  }, [show]);

  return (
    <Modal
      show={show}
      onHide={onHide}
      size="md"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header closeButton className={classes.header}>
        Error
      </Modal.Header>
      <Modal.Body className={classes.body}>{message}</Modal.Body>
      <Modal.Footer className={classes.footer}>
        <StyledButton className={classes.button} label="Ok" onClick={onHide} />
      </Modal.Footer>
    </Modal>
  );
}
