/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
import React, { useState, useEffect, useContext } from 'react';

import { connect } from 'react-redux';
import useForceUpdate from 'use-force-update';
import classnames from 'classnames';

import { actions } from '../../../resources/highlights';
import { HighlightContext } from '../../../context/index';
import classes from './styles.module.scss';

function HighlightMenu({ updateHighlight, deleteHighlight }) {
  const [currentColor, setCurrentColor] = useState(null);
  const [comment, setComment] = useState(null);
  const [isEnoughSpace, setIsEnoughSpace] = useState(true);

  const [
    isHighlightMenuVisible,
    setIsHighlightMenuVisible,
    currentHighlight,
    ,
    highlightRef,
  ] = useContext(HighlightContext);

  const forceUpdate = useForceUpdate();

  const changeColor = (color) => {
    updateHighlight({ ...currentHighlight, color });
    setIsHighlightMenuVisible(false);
  };

  const removeHighlight = () => {
    deleteHighlight(currentHighlight);
    setIsHighlightMenuVisible(false);
  };

  const changePositionOnScroll = () => {
    if (isHighlightMenuVisible) {
      forceUpdate();
    }
  };

  useEffect(() => {
    window.addEventListener('scroll', changePositionOnScroll);
    return () => {
      window.removeEventListener('scroll', changePositionOnScroll);
    };
  }, [isHighlightMenuVisible]);

  useEffect(() => {
    if (currentHighlight) {
      setCurrentColor(currentHighlight.color);
      setComment(currentHighlight.comment);
    }
  }, [currentHighlight]);

  useEffect(() => {
    if (
      window.innerHeight -
        highlightRef?.current?.getBoundingClientRect().bottom <
      172
    ) {
      setIsEnoughSpace(false);
    } else {
      setIsEnoughSpace(true);
    }
  }, [currentHighlight]);

  return (
    <>
      <div
        className={classes.overlay}
        style={{ display: isHighlightMenuVisible ? 'block' : 'none' }}
        onClick={(event) => {
          event.nativeEvent.stopImmediatePropagation();
          event.stopPropagation();
          setIsHighlightMenuVisible(false);
        }}
      />
      <div
        className={classnames(
          classes.HighlightMenu,
          isHighlightMenuVisible && classes.show,
          !isEnoughSpace && classes.top,
          currentHighlight && !currentHighlight.comment && classes.noComment
        )}
        onClick={(event) => {
          event.nativeEvent.stopImmediatePropagation();
          event.stopPropagation();
        }}
        style={{
          left:
            highlightRef?.current?.getBoundingClientRect().left +
            (highlightRef?.current?.getBoundingClientRect().right -
              highlightRef?.current?.getBoundingClientRect().left) /
              2 -
            68,
          top: isEnoughSpace
            ? highlightRef?.current?.getBoundingClientRect().bottom + 10
            : highlightRef?.current?.getBoundingClientRect().top -
              98 -
              10 -
              (currentHighlight.comment ? 70 : 0),
        }}
      >
        <div className={classes.deleteButtonContainer}>
          <button onClick={removeHighlight} type="button">
            Delete
          </button>
        </div>
        {currentHighlight && currentHighlight.comment && (
          <div className={classes.comment}>
            <textarea readOnly maxLength={255} value={comment} />
          </div>
        )}
        <div className={classes.colors}>
          <ul>
            <li
              className={classnames(
                classes.green,
                currentColor === 'green' && classes.active
              )}
              onClick={() => changeColor('green')}
            />
            <li
              className={classnames(
                classes.orange,
                currentColor === 'orange' && classes.active
              )}
              onClick={() => changeColor('orange')}
            />
            <li
              className={classnames(
                classes.red,
                currentColor === 'red' && classes.active
              )}
              onClick={() => changeColor('red')}
            />
            <li
              className={classnames(
                classes.gray,
                currentColor === 'gray' && classes.active
              )}
              onClick={() => changeColor('gray')}
            />
            <li
              className={classnames(
                classes.purple,
                currentColor === 'purple' && classes.active
              )}
              onClick={() => changeColor('purple')}
            />
            <li
              className={classnames(
                classes.blue,
                currentColor === 'blue' && classes.active
              )}
              onClick={() => changeColor('blue')}
            />
            <li
              className={classnames(
                classes.yellow,
                currentColor === 'yellow' && classes.active
              )}
              onClick={() => changeColor('yellow')}
            />
            <li
              className={classnames(
                classes.pink,
                currentColor === 'pink' && classes.active
              )}
              onClick={() => changeColor('pink')}
            />
          </ul>
        </div>
      </div>
    </>
  );
}

export default connect(null, {
  updateHighlight: actions.updateHighlight,
  deleteHighlight: actions.deleteHighlight,
})(HighlightMenu);
