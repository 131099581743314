import React, { useEffect, useState } from 'react';

import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';
import classnames from 'classnames';

import classes from './styles.module.scss';
import '../../../../../assets/bootstrap-tooltip.css';

const clipValue = {
  51: 210,
  52: 207,
  53: 206,
  54: 205,
  55: 204,
  56: 201,
  57: 198,
  58: 196,
  59: 191,
  60: 187,
  61: 183,
  62: 179,
  63: 175,
  64: 168,
  65: 164,
  66: 160,
  67: 152,
  68: 147,
  69: 142,
  70: 136,
  71: 130,
  72: 123,
  73: 117,
  74: 110,
  75: 104,
  76: 98,
  77: 91,
  78: 84,
  79: 78,
  80: 73,
  81: 67,
  82: 61,
  83: 56,
  84: 50,
  85: 46,
  86: 41,
  87: 36,
  88: 32,
  89: 28,
  90: 25,
  91: 20,
  92: 17,
  93: 14,
  94: 12,
  95: 10,
  96: 7,
  97: 5,
  98: 4,
  99: 3,
  100: 0,
};

export default function CircleChart({ percentage, title }) {
  const [rotation, setRotation] = useState(0);

  const setPercentage = () => {
    const percent = percentage;
    const deg = (360 * percent) / 100;

    setRotation(deg);
  };

  useEffect(() => {
    setPercentage();
  }, [percentage]);

  const renderTooltip = (props) => (
    <Tooltip className={classes.tooltip} {...props}>
      <span className={classes.tooltipContent}>
        - {title.toLowerCase()} ({percentage}%)
      </span>
    </Tooltip>
  );

  return (
    <OverlayTrigger
      placement="top"
      delay={{ show: 250, hide: 400 }}
      overlay={renderTooltip}
    >
      <div className={classes.CircleChart}>
        <div
          className={classnames(
            classes.progressPieChart,
            percentage > 50 && classes.gt50
          )}
        >
          <div className={classes.ppcProgress}>
            <div
              className={classes.ppcProgressFill}
              style={{ transform: `rotate(${rotation}deg)` }}
            />
          </div>
          <div className={classes.grayCircle} />
          <div
            className={classes.invertedGrayCircle}
            style={{
              clip: `rect(0px, 100px, ${clipValue[percentage]}px, 0px)`,
            }}
          />
          <div className={classes.ppcPercents}>
            <div className={classes.pccPercentsWrapper} />
          </div>
        </div>
        <h1>{title}</h1>
        <span className={classes.percentage}>{percentage}</span>
      </div>
    </OverlayTrigger>
  );
}
