import React from 'react';

import classnames from 'classnames';

import classes from './styles.module.scss';

export default function VideoProcessingSpinner() {
  return (
    <div className={classnames(classes.spinnerContainer)}>
      <div className={classes.ldsDefault}>
        <div />
        <div />
        <div />
        <div />
        <div />
        <div />
        <div />
        <div />
        <div />
        <div />
        <div />
        <div />
      </div>
    </div>
  );
}
