/* eslint-disable jsx-a11y/media-has-caption */
/* eslint-disable no-param-reassign */
import React, { useRef, useEffect, useState } from 'react';

import classnames from 'classnames';

import Charts from './Charts';
import Filters from './Filters';

import detectBrowser from '../../helpers/detectBrowser';
import classes from './styles.module.scss';

export default function VideoPlayerModal({
  show,
  onHide,
  video,
  videoUrl,
  smallVideoRef,
}) {
  const [wasVideoPlaying, setWasVideoPlaying] = useState(false);

  const browser = detectBrowser();

  const videoRef = useRef();

  useEffect(() => {
    if (videoUrl && videoRef.current) {
      videoRef.current.load();
    }
  }, [videoUrl]);

  useEffect(() => {
    if (videoRef && videoRef.current) {
      videoRef.current.currentTime = smallVideoRef.current.currentTime;
    }
  }, [smallVideoRef?.current?.currentTime]);

  useEffect(() => {
    if (show) {
      if (!smallVideoRef.current.paused && !smallVideoRef.current.ended) {
        videoRef.current.play();
        setWasVideoPlaying(true);
      } else {
        setWasVideoPlaying(false);
      }
      smallVideoRef.current.pause();
    } else {
      videoRef.current.pause();
      smallVideoRef.current.currentTime = videoRef.current.currentTime;
      if (wasVideoPlaying) {
        smallVideoRef.current.play();
      }
    }
  }, [show]);

  useEffect(() => {
    console.log('was video playing', wasVideoPlaying);
  }, [wasVideoPlaying]);

  useEffect(() => {
    const markVideoAsPlaying = () => {
      setWasVideoPlaying(true);
    };

    const markVideoAsNotPlaying = () => {
      setWasVideoPlaying(false);
    };

    if (videoRef && videoRef.current) {
      videoRef.current.addEventListener('playing', markVideoAsPlaying);
      videoRef.current.addEventListener('pause', markVideoAsNotPlaying);
      videoRef.current.addEventListener('ended', markVideoAsNotPlaying);
    }
    return () => {
      if (videoRef && videoRef.current) {
        videoRef.current.removeEventListener('playing', markVideoAsPlaying);
        videoRef.current.removeEventListener('pause', markVideoAsNotPlaying);
        videoRef.current.removeEventListener('ended', markVideoAsNotPlaying);
      }
    };
  }, [videoRef]);

  return (
    <div className={classnames(classes.VideoPlayerModal, show && classes.show)}>
      <div className={classes.videoContainer}>
        <div className={classes.playerContainer}>
          <div
            className={classnames(
              classes.fullScreenButton,
              browser === 'Google Chrome or Chromium' && classes.chrome,
              browser === 'Mozilla Firefox' && classes.firefox
            )}
            onClick={() => {
              onHide();
            }}
          />
          <video ref={videoRef} controls>
            {/* <source src={`${REACT_APP_STATIC_URL}${video ? video.path : ''}`} /> */}
            <source src={videoUrl} />
          </video>
          <div className={classes.chartsContainer}>
            <Charts video={video} videoRef={videoRef} />
          </div>
          <div className={classes.filtersContainer}>
            <Filters video={video} />
          </div>
        </div>
      </div>
      <div
        className={classes.overlay}
        onClick={() => {
          onHide();
        }}
      />
    </div>
  );
}
