import React from 'react';

import classnames from 'classnames';

import classes from './styles.module.scss';

export default function CheckBox({
  label,
  checked,
  onChange,
  isInvalid,
  errorMessage,
}) {
  /* eslint-disable jsx-a11y/no-noninteractive-tabindex */
  return (
    /* eslint-disable-next-line jsx-a11y/label-has-associated-control */
    <label
      className={classnames(classes.container, isInvalid && classes.invalid)}
    >
      <span className={classes.label}>{label}</span>
      <input type="checkbox" checked={checked} onChange={onChange} />
      <span className={classes.checkmark} tabIndex={0} />
      <span className={classes.errorMessage}>{errorMessage}</span>
    </label>
  );
}
