/* eslint-disable no-nested-ternary */
/* eslint-disable jsx-a11y/media-has-caption */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
import React, { useState, useRef, useEffect, useContext } from 'react';

import { useLastLocation } from 'react-router-last-location';
import { useLocation, useHistory } from 'react-router-dom';
import axios from 'axios';
import { connect } from 'react-redux';
import classnames from 'classnames';
import copy from 'copy-to-clipboard';

import { UiContext, FoldersContext } from '../../context/index';
import { REACT_APP_API_URL } from '../../constants/main';
import RenameFolderModal from '../../modals/RenameFolderModal';
import FileUploadModal from '../../modals/FileUploadModal';
import RenameFileModal from '../../modals/RenameFileModal';
import MoveFileModal from '../../modals/MoveFileModal';
import ShareEntityModal from '../../modals/ShareEntityModal';

import { actions as foldersActions } from '../../resources/folders';
import { actions as filesActions } from '../../resources/files';
import { actions as sharedEntitiesActions } from '../../resources/sharedEntities';
import useOnClickOutside from '../../helpers/useOnClickOutside';
import FileDetails from './FileDetails';
import Folder from './Folder';
import File from './File';
import PopupMessage from './PopupMessage';
import EditFile from './EditFile';
import addNewResearchIcon from '../../assets/images/addNewResearchIcon.svg';
import classes from './styles.module.scss';

function FileManager({
  deleteFolder,
  deleteFile,
  searchResults,
  setSearchResults,
  updateFolder,
  updateFile,
}) {
  const [isSortMenuVisible, setIsSortMenuVisible] = useState(false);
  const [currentSortOption, setCurrentSortOption] = useState('Name');
  const [currentSortOrder, setCurrentSortOrder] = useState('ASC');
  const [currentViewMode, setCurrentViewMode] = useState(null);
  const [currentFile, setCurrentFile] = useState(null);
  const [entityToShare, setEntityToShare] = useState(null);
  const [isRenameFolderModalVisible, setIsRenameFolderModalVisible] = useState(
    false
  );
  const [isFileUploadModalVisible, setIsFileUploadModalVisible] = useState(
    false
  );
  const [isRenameFileModalVisible, setIsRenameFileModalVisible] = useState(
    false
  );
  const [isMoveFileModalVisible, setIsMoveFileModalVisible] = useState(false);
  const [isShareEntityModalVisible, setIsShareEntityModalVisible] = useState(
    false
  );
  const [foldersPath, setFoldersPath] = useState([]);
  const [sharedFoldersPath, setSharedFoldersPath] = useState([]);
  const [currentPage, setCurrentPage] = useState(0);
  const [dataElementsCount, setDataElementsCount] = useState(null);
  const [dataElements, setDataElements] = useState([]);
  const [areDataElementsLoading, setAreDataElementsLoading] = useState([]);
  const [sharedEntities, setSharedEntities] = useState([]);
  const [isFileDetailsVisible, setIsFileDetailsVisible] = useState(false);
  const [currentDetailsFile, setCurrentDetailsFile] = useState(null);
  const [popupMessage, setPopupMessage] = useState('');
  const [isPopupMessageVisible, setIsPopupMessageVisible] = useState(false);

  const [isEditFileVisible, setIsEditFileVisible] = useState(false);
  const [currentEditingFile, setCurrentEditingFile] = useState(null);

  const [
    isSideBarExpanded,
    ,
    folderContentElement,
    setFolderContentElement,
    isSharedWithMeTabActive,
    setIsSharedWithMeTabActive,
  ] = useContext(UiContext);

  const [, setCurrentOpenFolder] = useContext(FoldersContext);

  const location = useLocation();
  const query = new URLSearchParams(location.search);
  const history = useHistory();

  const showEditFile = (file) => {
    console.log('opening file', file);
    updateFile({ ...file, lastTimeOpenedAt: new Date() });
    setCurrentEditingFile(file);
    setIsEditFileVisible(true);
    if (isSharedWithMeTabActive && !sharedFoldersPath.includes(file)) {
      setSharedFoldersPath((prevPath) => [...prevPath, file]);
    } else if (!isSharedWithMeTabActive && !foldersPath.includes(file)) {
      setFoldersPath((prevPath) => [...prevPath, file]);
    }
  };

  useEffect(() => {
    if (query.get('shared') === 'true' && query.get('account')) {
      axios({
        method: 'GET',
        url: `${REACT_APP_API_URL}/users/me`,
        headers: {
          authorization: `${
            JSON.parse(localStorage.getItem('authData')).accessToken
          }`,
        },
      })
        .then((response) => {
          if (response.data.email !== query.get('account')) {
            history.push(`/login?account=${query.get('account')}`);
          } else {
            setIsSharedWithMeTabActive(true);
          }
        })
        .catch((error) => console.log(error));
    }
  }, []);

  useEffect(() => {
    if (query.get('openFile') && sharedEntities && sharedEntities.length) {
      showEditFile(
        sharedEntities.find((entity) => entity.name === query.get('openFile'))
      );
    }
  }, [sharedEntities]);

  useEffect(() => {
    if (query.get('showShared') === 'true') {
      setIsSharedWithMeTabActive(true);
    }
  }, []);

  useEffect(() => {
    const pathWithoutFile = foldersPath.filter((elem) => !elem.type);
    setCurrentOpenFolder(pathWithoutFile[pathWithoutFile.length - 1]);
  }, [foldersPath]);

  const fileMangerRef = useRef();
  const sortMenuRef = useRef();
  const folderTitleRef = useRef();
  const folderCreatedAtRef = useRef();
  const mainContentRef = useRef();

  const lastLocation = useLastLocation();

  const toggleSortMenu = () => {
    setIsSortMenuVisible((prevState) => !prevState);
  };

  const hideSortMenu = () => {
    setIsSortMenuVisible(false);
  };

  const showRenameFolderModal = (folder) => {
    setCurrentFile(folder);
    setIsRenameFolderModalVisible(true);
  };

  const hideRenameFolderModal = () => {
    setIsRenameFolderModalVisible(false);
  };

  const showFileUploadModal = () => {
    setIsFileUploadModalVisible(true);
  };

  const hideFileUploadModal = () => {
    setIsFileUploadModalVisible(false);
  };

  const showRenameFileModal = (file) => {
    setCurrentFile(file);
    setIsRenameFileModalVisible(true);
  };

  const hideRenameFileModal = () => {
    setIsRenameFileModalVisible(false);
  };

  const showMoveFileModal = (file) => {
    setCurrentFile(file);
    setIsMoveFileModalVisible(true);
  };

  const hideMoveFileModal = () => {
    setIsMoveFileModalVisible(false);
  };

  const showShareEntityModal = (entity) => {
    setEntityToShare(entity);
    setIsShareEntityModalVisible(true);
  };

  const hideShareEntityModal = () => {
    setIsShareEntityModalVisible(false);
  };

  const showFileDetails = (file) => {
    setCurrentDetailsFile(file);
    setIsFileDetailsVisible(true);
  };

  const hideFileDetails = () => {
    setIsFileDetailsVisible(false);
  };

  const hideEditFile = () => {
    setCurrentEditingFile(null);
    setIsEditFileVisible(false);
  };

  const getSortOptions = () => {
    let sortField;

    switch (currentSortOption) {
      case 'Name':
        sortField = 'name';
        break;
      case 'Last Created':
        sortField = 'createdAt';
        break;
      case 'Last Opened':
        sortField = 'lastTimeOpenedAt';
        break;
      default:
        sortField = 'name';
    }

    return { sortField, currentSortOrder };
  };

  const moveUp = () => {
    setDataElementsCount(null);
    setFoldersPath((prevPath) => {
      const newPath = [...prevPath];
      newPath.pop();
      return newPath;
    });
    setCurrentPage(0);
    setDataElements([]);
  };

  useOnClickOutside(sortMenuRef, hideSortMenu);

  const toggleViewMode = () => {
    if (!foldersPath.length) {
      setCurrentPage(0);
      setFoldersPath([]);
      setDataElements([]);
      setDataElementsCount(null);
    }
    // eslint-disable-next-line consistent-return
    setCurrentViewMode((prevMode) => {
      if (prevMode === 'grid') {
        return 'list';
      }
      if (prevMode === 'list' || prevMode === null) {
        return 'grid';
      }
    });
  };

  const fetchSharedEntities = (folder) => {
    axios({
      method: 'GET',
      url: folder
        ? `${REACT_APP_API_URL}/share/content`
        : `${REACT_APP_API_URL}/share`,
      params: {
        ...getSortOptions(),
        folderId: folder ? folder.id : null,
      },
      headers: {
        authorization: `${
          JSON.parse(localStorage.getItem('authData')).accessToken
        }`,
      },
    })
      .then((response) => {
        setSharedEntities(response.data);
        if (folder) {
          setSharedFoldersPath((prevState) => {
            return [...prevState, folder];
          });
          updateFolder({ ...folder, lastTimeOpenedAt: new Date() });
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  useEffect(() => {
    if (!dataElements.length) {
      return;
    }
    fetchSharedEntities(null, { query: { ...getSortOptions() } });
    axios({
      method: 'GET',
      url: `${REACT_APP_API_URL}/folders/content`,
      params: {
        ...getSortOptions(),
        pageIndex: 0,
        pageSize: dataElements.length,
        folderId: foldersPath.length
          ? foldersPath[foldersPath.length - 1].id
          : null,
      },
      headers: {
        authorization: `${
          JSON.parse(localStorage.getItem('authData')).accessToken
        }`,
      },
    })
      .then((response) => {
        setDataElements(response.data.data);
        setDataElementsCount(+response.data.count);
        setAreDataElementsLoading(false);
      })
      .catch((error) => {
        console.log(error);
      });
  }, [currentSortOption, currentSortOrder]);

  useEffect(() => {
    if (folderContentElement.status === 'added') {
      setDataElements((prevState) => {
        return [...prevState, folderContentElement.element];
      });
    }

    if (folderContentElement.status === 'moved') {
      axios({
        method: 'GET',
        url: `${REACT_APP_API_URL}/folders/content`,
        params: {
          ...getSortOptions(),
          pageIndex: 0,
          pageSize: dataElements.length,
          folderId: foldersPath.length
            ? foldersPath[foldersPath.length - 1].id
            : null,
        },
        headers: {
          authorization: `${
            JSON.parse(localStorage.getItem('authData')).accessToken
          }`,
        },
      })
        .then((response) => {
          setDataElements(response.data.data);
          setDataElementsCount(+response.data.count);
          setAreDataElementsLoading(false);
        })
        .catch((error) => {
          console.log(error);
        });
    }
  }, [folderContentElement]);

  useEffect(() => {
    if (dataElements.length === dataElementsCount || !mainContentRef.current) {
      return;
    }
    setAreDataElementsLoading(true);

    axios({
      method: 'GET',
      url: `${REACT_APP_API_URL}/folders/content`,
      params: {
        ...getSortOptions(),
        pageIndex: currentPage,
        pageSize:
          currentViewMode === 'list' || currentViewMode === null
            ? 20
            : Math.ceil(
                ((window.innerHeight - 192 - 311) / 67) *
                  Math.floor(
                    (mainContentRef.current.clientWidth - 19 - 64) / (271 + 18)
                  )
              ),
        folderId: foldersPath.length
          ? foldersPath[foldersPath.length - 1].id
          : null,
      },
      headers: {
        authorization: `${
          JSON.parse(localStorage.getItem('authData')).accessToken
        }`,
      },
    })
      .then((response) => {
        if (foldersPath.length) {
          updateFolder({
            ...foldersPath[foldersPath.length - 1],
            lastTimeOpenedAt: new Date(),
          });
        }
        setDataElements((prevState) => {
          const newState =
            currentPage === 0
              ? response.data.data
              : [...prevState, ...response.data.data];
          return newState;
        });
        setDataElementsCount(+response.data.count);
        setAreDataElementsLoading(false);
      })
      .catch((error) => {
        console.log(error);
      });
  }, [currentPage, foldersPath]);

  const openFolder = (folder) => {
    setSearchResults(['empty']);
    setFoldersPath((prevPath) => {
      const newPath = [...prevPath, folder];
      return newPath;
    });
    setCurrentPage(0);
    setDataElementsCount(null);
    setDataElements([]);
  };

  useEffect(() => {
    if (query.get('openFileFromSearch')) {
      hideEditFile();
      setFoldersPath([]);
      history.push('/home');
      showEditFile(searchResults[0]);
      setSearchResults(['empty']);
    } else if (query.get('openFolderFromSearch')) {
      hideEditFile();
      setFoldersPath([]);
      history.push('/home');
      openFolder(searchResults[0]);
    }
  }, [location]);

  const openSharedFolder = (folder) => {
    fetchSharedEntities(folder);
  };

  // eslint-disable-next-line consistent-return
  const moveUpSharedFolder = () => {
    const url =
      sharedFoldersPath[sharedFoldersPath.length - 1].parentFolderId &&
      sharedFoldersPath.length !== 1
        ? `${REACT_APP_API_URL}/share/content`
        : `${REACT_APP_API_URL}/share`;
    axios({
      method: 'GET',
      url,
      params: {
        ...getSortOptions(),
        folderId:
          sharedFoldersPath[sharedFoldersPath.length - 1].parentFolderId,
      },
      headers: {
        authorization: `${
          JSON.parse(localStorage.getItem('authData')).accessToken
        }`,
      },
    })
      .then((response) => {
        setSharedEntities(response.data);
        setSharedFoldersPath((prevPath) => {
          const newPath = [...prevPath];
          newPath.pop();
          return newPath;
        });
      })
      .catch((error) => console.log(error));
  };

  const changeSortOrder = () => {
    // eslint-disable-next-line consistent-return
    setCurrentSortOrder((prevSortOrder) => {
      if (prevSortOrder === 'DESC') {
        return 'ASC';
      }
      if (prevSortOrder === 'ASC') {
        return 'DESC';
      }
    });
  };

  useEffect(() => {
    const detectScrollToBottom = () => {
      if (
        window.innerHeight + window.scrollY >=
        fileMangerRef.current.offsetHeight + 64 - 200
      ) {
        if (areDataElementsLoading) {
          return;
        }
        setAreDataElementsLoading(true);
        setCurrentPage((prevPage) => prevPage + 1);
      }
    };
    window.addEventListener('scroll', detectScrollToBottom);
    return () => {
      window.removeEventListener('scroll', detectScrollToBottom);
    };
  }, [areDataElementsLoading]);

  useEffect(() => {
    fetchSharedEntities();
    if (lastLocation && lastLocation.pathname.includes('shared')) {
      setIsSharedWithMeTabActive(true);
    }
  }, []);

  const getSharedEntityLink = (entity) => {
    axios({
      method: 'POST',
      url: `${REACT_APP_API_URL}/share/getLink`,
      data: {
        entityId: entity.id,
        entityType: entity.type ? 'file' : 'folder',
      },
      headers: {
        authorization: `${
          JSON.parse(localStorage.getItem('authData')).accessToken
        }`,
      },
    })
      .then((response) => {
        // eslint-disable-next-line no-use-before-define
        copyLinkToClipboard(response.data.link);
      })
      .catch((error) => console.log(error));
  };

  const generateFolders = (dataArray, shared) => {
    return dataArray.map((folder, index) => {
      return !folder.type ? (
        <Folder
          onClick={() => {
            if (shared) {
              openSharedFolder(folder);
            } else {
              openFolder(folder);
            }
          }}
          folder={folder}
          index={index}
          folderTitleRef={folderTitleRef}
          folderCreatedAtRef={folderCreatedAtRef}
          currentViewMode={currentViewMode}
          showShareEntityModal={showShareEntityModal}
          openFolder={shared ? openSharedFolder : openFolder}
          showRenameFolderModal={showRenameFolderModal}
          deleteFolder={deleteFolder}
          setFolderContentElement={setFolderContentElement}
          showMoveFileModal={showMoveFileModal}
          key={folder.id}
          shared={shared}
          getSharedEntityLink={getSharedEntityLink}
        />
      ) : null;
    });
  };

  const generateFiles = (dataArray, shared) => {
    return dataArray.map((file) => {
      return file.type ? (
        <File
          onClick={() => {
            showEditFile(file);
          }}
          file={file}
          key={file.id}
          currentViewMode={currentViewMode}
          showFileDetails={showFileDetails}
          showShareEntityModal={showShareEntityModal}
          showRenameFileModal={showRenameFileModal}
          deleteFile={deleteFile}
          setFolderContentElement={setFolderContentElement}
          showMoveFileModal={showMoveFileModal}
          shared={shared}
          openFile={showEditFile}
          getSharedEntityLink={getSharedEntityLink}
        />
      ) : null;
    });
  };

  const showPopupMessage = (message) => {
    setPopupMessage(message);
    setIsPopupMessageVisible(true);
  };

  async function copyLinkToClipboard(link) {
    /* if (navigator.clipboard) {
      await navigator.clipboard.writeText(link);
      showPopupMessage('The link was copied');
    } else {
      const linkElem = document.createElement('input');
      linkElem.value = link;
      document.body.appendChild(linkElem);
      linkElem.select();
      document.execCommand('copy');
      showPopupMessage('The link was copied');
      linkElem.remove();
    } */
    copy(link);
    showPopupMessage('The link was copied');
  }

  const hidePopupMessage = () => {
    setIsPopupMessageVisible(false);
  };

  const moveToFolder = (folder) => {
    setCurrentPage(0);
    setDataElementsCount(null);
    setDataElements([]);
    hideEditFile();
    // removeCurrentEditingFileFromPath();
    const indexOfFolder = isSharedWithMeTabActive
      ? sharedFoldersPath.indexOf(folder)
      : foldersPath.indexOf(folder);
    if (isSharedWithMeTabActive) {
      axios({
        method: 'GET',
        url: `${REACT_APP_API_URL}/share/content`,
        params: {
          ...getSortOptions(),
          folderId: folder.id,
        },
        headers: {
          authorization: `${
            JSON.parse(localStorage.getItem('authData')).accessToken
          }`,
        },
      })
        .then((response) => {
          setSharedEntities(response.data);
          setSharedFoldersPath((prevState) => {
            return prevState.slice(0, indexOfFolder + 1);
          });
          updateFolder({ ...folder, lastTimeOpenedAt: new Date() });
        })
        .catch((error) => {
          console.log(error);
        });
    } else {
      setFoldersPath((prevState) => {
        return prevState.slice(0, indexOfFolder + 1);
      });
    }
  };

  const moveToRootFolder = () => {
    history.replace('/');
    if (isEditFileVisible) {
      hideEditFile();
    }
    if (isSharedWithMeTabActive) {
      if (!sharedFoldersPath.length) {
        return;
      }
      setSharedFoldersPath([]);
      fetchSharedEntities();
    } else {
      if (!foldersPath.length) {
        return;
      }
      setDataElementsCount(null);
      setFoldersPath([]);
    }
  };

  useEffect(() => {
    hideEditFile();
    if (isSharedWithMeTabActive) {
      if (
        sharedFoldersPath.length &&
        sharedFoldersPath[sharedFoldersPath.length - 1].type
      ) {
        showEditFile(sharedFoldersPath[sharedFoldersPath.length - 1]);
      }
    } else if (!isSharedWithMeTabActive) {
      if (foldersPath.length && foldersPath[foldersPath.length - 1].type) {
        showEditFile(foldersPath[foldersPath.length - 1]);
      }
    }
  }, [isSharedWithMeTabActive]);

  const entitiesList = (
    <main
      style={{
        paddingTop:
          currentViewMode === 'list' || currentViewMode === null ? 162 : 128,
      }}
      ref={mainContentRef}
      className={classnames(
        classes.mainContent,
        currentViewMode === 'grid' && classes.gridView,
        (currentViewMode === 'list' || currentViewMode === null) &&
          classes.listView
      )}
    >
      <div className={classes.foldersContainer}>
        {!!foldersPath.length && (
          <div className={classes.folder} onClick={moveUp}>
            ...
          </div>
        )}
        {currentViewMode !== 'grid' && (
          <div
            onClick={showFileUploadModal}
            className={classnames(classes.folder, classes.addNewResearch)}
          >
            Add new research
          </div>
        )}
        {searchResults[0] !== 'empty'
          ? generateFolders(searchResults)
          : dataElements && generateFolders(dataElements)}
        <div className={classes.filesContainer}>
          {currentViewMode === 'grid' && (
            <>
              <header>
                <h2>Reports</h2>
              </header>
              <div
                className={classes.addResearchButton}
                onClick={showFileUploadModal}
              >
                <img src={addNewResearchIcon} alt="Add new research icon" />
                <span>ADD NEW RESEARCH</span>
              </div>
            </>
          )}
          {searchResults[0] !== 'empty'
            ? generateFiles(searchResults)
            : dataElements && generateFiles(dataElements)}
        </div>
      </div>
    </main>
  );

  const sharedEntitiesList = (
    <main
      style={{
        paddingTop:
          currentViewMode === 'list' || currentViewMode === null ? 162 : 128,
      }}
      ref={mainContentRef}
      className={classnames(
        classes.mainContent,
        currentViewMode === 'grid' && classes.gridView,
        (currentViewMode === 'list' || currentViewMode === null) &&
          classes.listView
      )}
    >
      <div className={classes.foldersContainer}>
        {!!sharedFoldersPath.length && (
          <div className={classes.folder} onClick={moveUpSharedFolder}>
            ...
          </div>
        )}
        {searchResults[0] !== 'empty'
          ? generateFolders(searchResults, true)
          : sharedEntities && generateFolders(sharedEntities, true)}
        <div className={classes.filesContainer}>
          {currentViewMode === 'grid' && (
            <>
              <header>
                <h2>Reports</h2>
              </header>
              <div
                className={classes.addResearchButton}
                onClick={showFileUploadModal}
              >
                <img src={addNewResearchIcon} alt="Add new research icon" />
                <span>ADD NEW RESEARCH</span>
              </div>
            </>
          )}
          {searchResults[0] !== 'empty'
            ? generateFiles(searchResults, true)
            : sharedEntities && generateFiles(sharedEntities, true)}
        </div>
      </div>
    </main>
  );

  return (
    <div className={classes.FileManager} ref={fileMangerRef}>
      <header
        className={classes.header}
        style={{ width: `calc(100% - ${isSideBarExpanded ? '235' : '56'}px)` }}
      >
        <div className={classes.menu}>
          {isSharedWithMeTabActive ? (
            <div className={classes.breadCrumbs}>
              <h1 onClick={moveToRootFolder}>My Lab</h1>
              {!!sharedFoldersPath.length && (
                <i className={classes.breadCrumbArrow}>&gt;</i>
              )}
              {sharedFoldersPath.map((folder, index) => {
                return (
                  <>
                    <div
                      key={folder.id}
                      className={classnames(
                        classes.crumb,
                        index === sharedFoldersPath.length - 1 &&
                          classes.lastOne
                      )}
                      onClick={() => {
                        moveToFolder(folder);
                      }}
                    >
                      {folder.name}
                    </div>
                    {index !== sharedFoldersPath.length - 1 && (
                      <i className={classes.breadCrumbArrow}>&gt;</i>
                    )}
                  </>
                );
              })}
            </div>
          ) : (
            <div className={classes.breadCrumbs}>
              <h1 onClick={moveToRootFolder}>My Lab</h1>
              {!!foldersPath.length && (
                <i className={classes.breadCrumbArrow}>&gt;</i>
              )}
              {foldersPath.map((folder, index) => {
                return (
                  <>
                    <div
                      key={folder.id}
                      className={classnames(
                        classes.crumb,
                        index === foldersPath.length - 1 && classes.lastOne
                      )}
                      onClick={() => {
                        moveToFolder(folder);
                      }}
                    >
                      {folder.name}
                    </div>
                    {index !== foldersPath.length - 1 && (
                      <i className={classes.breadCrumbArrow}>&gt;</i>
                    )}
                  </>
                );
              })}
            </div>
          )}
          <div
            className={classes.sortMenu}
            onClick={toggleSortMenu}
            ref={sortMenuRef}
          >
            {!isEditFileVisible && (
              <span>
                {currentSortOption}
                <i
                  className={classnames(
                    currentSortOrder === 'ASC' && classes.asc
                  )}
                  onClick={(event) => {
                    event.stopPropagation();
                    hideSortMenu();
                    changeSortOrder();
                  }}
                >
                  Sort order
                </i>
              </span>
            )}
            <div
              className={classnames(
                classes.menuContent,
                isSortMenuVisible && classes.visible
              )}
            >
              <ul>
                <li
                  className={classnames(
                    classes.name,
                    currentSortOption === 'Name' && classes.selected
                  )}
                  onClick={() => setCurrentSortOption('Name')}
                >
                  Name
                </li>
                <li
                  className={classnames(
                    classes.lastCreated,
                    currentSortOption === 'Last Created' && classes.selected
                  )}
                  onClick={() => setCurrentSortOption('Last Created')}
                >
                  Last Created
                </li>
                <li
                  className={classnames(
                    classes.lastOpened,
                    currentSortOption === 'Last Opened' && classes.selected
                  )}
                  onClick={() => setCurrentSortOption('Last Opened')}
                >
                  Last Opened
                </li>
              </ul>
            </div>
          </div>
          {!isEditFileVisible && (
            <div
              onClick={toggleViewMode}
              className={classnames(
                classes.viewModeSwitcher,
                currentViewMode === 'grid' && classes.gridOption,
                (currentViewMode === 'list' || currentViewMode === null) &&
                  classes.listOption
              )}
            />
          )}
        </div>
        {!isEditFileVisible && (
          <div className={classes.filesInfo}>
            <h2>Folders</h2>
            <div
              className={classes.foldersListHeader}
              style={{
                display:
                  currentViewMode === 'list' || currentViewMode === null
                    ? 'flex'
                    : 'none',
              }}
            >
              <span className={classes.name}>
                <span>Name</span>
              </span>
              <span className={classes.created}>Created</span>
              <span className={classes.modified}>Last modified</span>
              <div className={classes.emptyContainer} />
            </div>
          </div>
        )}
      </header>
      {isEditFileVisible ? (
        <EditFile
          video={currentEditingFile}
          getSharedEntityLink={getSharedEntityLink}
        />
      ) : isSharedWithMeTabActive ? (
        sharedEntitiesList
      ) : (
        entitiesList
      )}
      <FileDetails
        show={isFileDetailsVisible}
        onHide={hideFileDetails}
        file={currentDetailsFile}
      />
      <RenameFolderModal
        folder={currentFile}
        show={isRenameFolderModalVisible}
        onHide={hideRenameFolderModal}
      />
      <FileUploadModal
        show={isFileUploadModalVisible}
        onHide={hideFileUploadModal}
        folder={foldersPath.length ? foldersPath[foldersPath.length - 1] : null}
      />
      <RenameFileModal
        file={currentFile}
        show={isRenameFileModalVisible}
        onHide={hideRenameFileModal}
      />
      <MoveFileModal
        file={currentFile}
        show={isMoveFileModalVisible}
        onHide={hideMoveFileModal}
        sortOptions={{ ...getSortOptions() }}
      />
      <ShareEntityModal
        entity={entityToShare}
        show={isShareEntityModalVisible}
        onHide={hideShareEntityModal}
        showPopupMessage={showPopupMessage}
      />
      <PopupMessage
        show={isPopupMessageVisible}
        onHide={hidePopupMessage}
        message={popupMessage}
      />
    </div>
  );
}

export default connect(
  (state) => ({
    folders: state.folders.items,
    areFoldersFetching: state.folders.isFetching,
    files: state.files.items,
    areFilesFetching: state.files.isFetching,
    /*  pageIndex: state.folders.pageIndex,
    totalItemsCount: state.folders.totalItemsCount, */
    sharedEntities: state.sharedEntities.items,
    areSharedEntitiesFetching: state.sharedEntities.isFetching,
  }),
  {
    // fetchFolders: foldersActions.fetchFolders,
    deleteFolder: foldersActions.deleteFolder,
    updateFolder: foldersActions.updateFolder,
    // fetchFiles: filesActions.fetchFiles,
    deleteFile: filesActions.deleteFile,
    updateFile: filesActions.updateFile,
    fetchSharedEntities: sharedEntitiesActions.fetchSharedEntitys,
  }
)(FileManager);
