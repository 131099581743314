import React, { useEffect, useState, useContext } from 'react';

import axios from 'axios';
import { connect } from 'react-redux';
import classnames from 'classnames';
import Modal from 'react-bootstrap/Modal';

import { UiContext } from '../../context/index';
import { REACT_APP_API_URL } from '../../constants/main';
import { actions as filesActions } from '../../resources/files';
import { actions as foldersActions } from '../../resources/folders';
import StyledButton from '../../components/Form/StyledButton';
import '../../assets/bootstrap-modal.css';
import classes from './styles.module.scss';

function MoveFileModal({
  show,
  onHide,
  file,
  updateFile,
  updateFolder,
  fetchFoldersAction,
  sortOptions,
  fetchFilesAction,
}) {
  const [folders, setFolders] = useState(null);
  const [areFoldersFetching, setAreFoldersFetching] = useState(false);
  const [files, setFiles] = useState(null);
  const [areFilesFetching, setAreFilesFetching] = useState(false);
  const [foldersPath, setFoldersPath] = useState([]);

  const [, , , setFolderContentElement] = useContext(UiContext);

  const fetchData = (dataType, folderId) => {
    const queryParams = {};
    if (folderId) {
      queryParams.folderId = folderId;
    }
    if (dataType === 'folders') {
      setAreFoldersFetching(true);
    } else if (dataType === 'files') {
      setAreFilesFetching(true);
    }
    axios
      .get(`${REACT_APP_API_URL}/${dataType}/`, {
        params: queryParams,
        headers: {
          authorization: `${
            JSON.parse(localStorage.getItem('authData')).accessToken
          }`,
        },
      })
      .then((response) => {
        if (dataType === 'folders') {
          setFolders(response.data);
          setAreFoldersFetching(false);
        } else if (dataType === 'files') {
          setFiles(response.data);
          setAreFilesFetching(false);
        }
      })
      .catch((error) => console.log(error));
  };

  useEffect(() => {
    fetchData('folders');
    fetchData('files');
  }, []);

  useEffect(() => {
    if (show) {
      fetchData('folders');
      fetchData('files');
    }
  }, [show]);

  const openFolder = (folder) => {
    fetchData('folders', folder.id);
    fetchData('files', folder.id);
    setFoldersPath((prevPath) => {
      return [...prevPath, folder];
    });
  };

  const moveOneFolderUp = () => {
    if (!foldersPath.length) {
      return;
    }
    fetchData('folders', foldersPath[foldersPath.length - 1].parentFolderId);
    fetchData('files', foldersPath[foldersPath.length - 1].parentFolderId);
    setFoldersPath((prevPath) => {
      const newPath = [...prevPath];
      newPath.pop();
      return newPath;
    });
  };

  const setParentFolder = () => {
    if (!file) {
      return;
    }
    if (file.type) {
      updateFile({
        ...file,
        parentFolderId: foldersPath.length
          ? foldersPath[foldersPath.length - 1].id
          : null,
      }).then(setFolderContentElement({ element: null, status: 'moved' }));
    } else {
      updateFolder({
        ...file,
        parentFolderId: foldersPath.length
          ? foldersPath[foldersPath.length - 1].id
          : null,
      }).then(setFolderContentElement({ element: null, status: 'moved' }));
    }
  };

  return (
    <Modal
      show={show}
      onHide={() => {
        onHide();
        setFoldersPath([]);
      }}
      size="md"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      className={classes.MoveFileModal}
    >
      <Modal.Header closeButton className={classes.header}>
        <div className={classes.headerContent}>
          <i onClick={moveOneFolderUp}>arrow left</i>
          <span className={classes.title}>
            {foldersPath.length
              ? foldersPath[foldersPath.length - 1].name
              : 'Root Directory'}
          </span>
        </div>
      </Modal.Header>
      <Modal.Body className={classes.body}>
        <div className={classes.filesContainer}>
          {!areFoldersFetching && folders && file && (
            <div>
              {!!foldersPath.length && (
                <div
                  className={classnames(classes.moveUp, classes.folder)}
                  onClick={moveOneFolderUp}
                >
                  <h1>...</h1>
                  <i className={classes.openFolderIcon}>Open folder</i>
                </div>
              )}
              {folders.map((folder) => {
                return folder.id !== file.id ? (
                  <div
                    key={folder.id}
                    className={classes.folder}
                    onClick={() => openFolder(folder)}
                  >
                    <h1>{folder.name}</h1>
                    <i className={classes.openFolderIcon}>Open folder</i>
                  </div>
                ) : null;
              })}
            </div>
          )}
        </div>
        <div className={classes.filesContainer}>
          {!areFilesFetching &&
            files &&
            file &&
            files.map((fetchedFile) => {
              return (
                <div key={fetchedFile.id} className={classes.file}>
                  <h1>{fetchedFile.name}</h1>
                </div>
              );
            })}
        </div>
      </Modal.Body>
      <Modal.Footer className={classes.footer}>
        <StyledButton
          label="Move"
          onClick={() => {
            setParentFolder();
            onHide();
            fetchFoldersAction(null, { query: sortOptions });
            fetchFilesAction(null, { query: sortOptions });
            setFoldersPath([]);
          }}
        />
      </Modal.Footer>
    </Modal>
  );
}

export default connect(null, {
  fetchFoldersAction: foldersActions.fetchFolders,
  fetchFilesAction: filesActions.fetchFiles,
  updateFile: filesActions.updateFile,
  updateFolder: foldersActions.updateFolder,
})(MoveFileModal);
