import React, { useEffect } from 'react';

import axios from 'axios';
import { useParams, useHistory } from 'react-router-dom';

import { REACT_APP_API_URL } from '../../constants/main';

export default function ShareLinkPage() {
  const { entityType, link } = useParams();
  const history = useHistory();

  const activateLink = () => {
    axios({
      method: 'POST',
      url: `${REACT_APP_API_URL}/share/`,
      data: {
        sharedLinkUrl: link,
        entityType,
      },
      headers: {
        authorization: `${
          JSON.parse(localStorage.getItem('authData')).accessToken
        }`,
      },
    })
      .then((response) => {
        history.replace(
          entityType === 'file'
            ? `/presentation?videoId=${response.data.entityId}&sharedByLinkMode=true`
            : '/'
        );
      })
      .catch((error) => console.log(error));
  };

  useEffect(() => {
    activateLink();
  }, []);

  return <div>Processing link...</div>;
}
