import React from 'react';

import { PieChart } from 'react-minimal-pie-chart';

import classes from './styles.module.scss';

export default function PieChartComponent({ title, women, men }) {
  return (
    <div className={classes.PieChartComponent}>
      <h1>{title}</h1>
      <div className={classes.dataContainer}>
        <div className={classes.chartContainer}>
          <div className={classes.chart}>
            <PieChart
              startAngle={25}
              data={[
                { title: 'women', value: +women, color: '#FF9800' },
                { title: 'men', value: +men, color: '#2ECC71' },
              ]}
            />
            <div className={classes.innerCircle} />
          </div>
        </div>
        <div className={classes.percentages}>
          <ul>
            <li className={classes.women}>- women ({women}%)</li>

            <li className={classes.men}>- men ({men}%)</li>
          </ul>
        </div>
      </div>
    </div>
  );
}
