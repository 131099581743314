// export const NODE_ENV = process.env.NODE_ENV;
export const { REACT_APP_API_URL, REACT_APP_STATIC_URL } = process.env;
export const HIGHLIGHT_COLORS = {
  GREEN: ['#2ecc71', 'rgba(46,204,113, 0.3)'],
  ORANGE: ['#ff9800', 'rgba(255,152,0, 0.3)'],
  RED: ['#f44336', 'rgba(244,67,54, 0.3)'],
  GRAY: ['#9e9e9e', 'rgba(158,158,158, 0.3)'],
  PURPLE: ['#9c27b0', 'rgba(156,39,176, 0.3)'],
  BLUE: ['#339de0', 'rgba(51,157,224, 0.3)'],
  YELLOW: ['#f3db05', 'rgba(243,219,5, 0.3)'],
  PINK: ['#cc2e8a', 'rgba(204,46,138, 0.3)'],
};
export const VIDEO_PROCESSING_STATUSES = {
  NOT_STARTTED: 'NOT_STARTTED',
  IN_PROGRESS: 'IN_PROGRESS',
  SUCCESS: 'SUCCESS',
  ERROR: 'ERROR',
};
