import React, { useState, useEffect, useRef } from 'react';

import { connect } from 'react-redux';

import classnames from 'classnames';

import Comment from './Comment';

import { actions } from '../../../../../resources/comments';
import formatTimeStamp from '../../../../../helpers/formatTimeStamp';
import classes from './styles.module.scss';

let removeHighlightTimer;

function scrollToTargetAdjusted(target, container) {
  const element = target.current;
  const headerOffset = 151;
  const elementPosition = element.getBoundingClientRect().top;
  const offsetPosition = elementPosition - headerOffset;

  container.current.scrollTo({
    top: offsetPosition,
    behavior: 'smooth',
  });
}

function Thread({
  time,
  comments,
  sendComment,
  deleteComment,
  updateComment,
  fetchThreads,
  currentTimeStamp,
  commentsRef,
}) {
  const [commentText, setCommentText] = useState('');
  const [isHighlighted, setIsHighlighted] = useState(false);
  const [isAnimationDisabled, setIsAnimationDisabled] = useState(true);

  const threadRef = useRef();

  const removeComment = (comment) => {
    deleteComment(comment)
      .then(() => fetchThreads())
      .catch((error) => console.log(error));
  };

  const editComment = (comment) => {
    updateComment(comment)
      .then(() => fetchThreads())
      .catch((error) => console.log(error));
  };

  useEffect(() => {
    if (
      currentTimeStamp !== undefined &&
      currentTimeStamp === time &&
      threadRef.current
    ) {
      setTimeout(() => {
        scrollToTargetAdjusted(threadRef, commentsRef);
        setIsHighlighted(true);
      }, 100);
      clearTimeout(removeHighlightTimer);
      removeHighlightTimer = setTimeout(() => {
        setIsHighlighted(false);
        setIsAnimationDisabled(false);
      }, 3100);
    } else {
      setIsHighlighted(false);
      setIsAnimationDisabled(true);
    }
  }, [currentTimeStamp, threadRef]);

  return (
    <div
      className={classnames(classes.Thread, isHighlighted && classes.highlight)}
      style={{
        transition: isAnimationDisabled ? '' : 'background-color 1s ease-in',
      }}
      ref={threadRef}
    >
      <span className={classes.timeStamp}>{formatTimeStamp(time, true)}</span>
      <main>
        {comments &&
          [...comments]
            .sort((a, b) => new Date(a.createdAt) - new Date(b.createdAt))
            .map((comment) => (
              <Comment
                key={comment.id}
                id={comment.id}
                author={comment.User.email}
                text={comment.text}
                date={comment.createdAt}
                onDelete={() => removeComment(comment)}
                onEdit={editComment}
              />
            ))}
      </main>
      <footer className={classes.addCommentBox}>
        <textarea
          placeholder="add a new comment"
          value={commentText}
          onChange={(event) => setCommentText(event.target.value)}
        />
        <i
          className={classes.sendComment}
          onClick={() => {
            sendComment(commentText, comments[0].threadId);
            setCommentText('');
          }}
        >
          Send comment
        </i>
      </footer>
    </div>
  );
}

export default connect(null, {
  deleteComment: actions.deleteComment,
  updateComment: actions.updateComment,
})(Thread);
