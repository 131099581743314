/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
import React, { useState, useRef, useContext } from 'react';

import classnames from 'classnames';

import { HighlightContext } from '../../../../context';
import { HIGHLIGHT_COLORS } from '../../../../constants/main';
import useOnClickOutside from '../../../../helpers/useOnClickOutside';

import FileUploadModal from '../../../../modals/FileUploadModal';
import classes from './styles.module.scss';

export default function LeftMenu({ setHighlightColor, highlightColor, video }) {
  const [isHighlightsMenuVisible, setIsHighlightsMenuVisible] = useState(false);
  const [isFileUploadModalVisible, setIsFileUploadModalVisible] = useState(
    false
  );
  const [
    isUploadWatermarkModalVisible,
    setIsUploadWatermarkModalVisible,
  ] = useState(false);

  const [
    ,
    ,
    ,
    ,
    ,
    ,
    isAddHighlightModeActive,
    setIsAddHighlightModeActive,
  ] = useContext(HighlightContext);

  const highlightsMenuRef = useRef();

  const toggleHighlightsMenu = () => {
    setIsAddHighlightModeActive((prevState) => {
      if (prevState) {
        setIsHighlightsMenuVisible(false);
        return !prevState;
      }
      setIsHighlightsMenuVisible(true);
      return !prevState;
    });
  };

  const hideHighlightsMenu = () => {
    setIsHighlightsMenuVisible(false);
  };

  const showFileUploadModal = () => {
    setIsFileUploadModalVisible(true);
  };

  const hideFileUploadModal = () => {
    setIsFileUploadModalVisible(false);
  };

  const showUploadWatermarkModal = () => {
    setIsUploadWatermarkModalVisible(true);
  };

  const hideUploadWatermarkModal = () => {
    setIsUploadWatermarkModalVisible(false);
  };

  const setActiveColor = (event, color) => {
    event.stopPropagation();
    setHighlightColor(color);
    hideHighlightsMenu();
  };

  useOnClickOutside(highlightsMenuRef, hideHighlightsMenu);

  return (
    <div className={classes.LeftMenu}>
      <div className={classes.undoRedo}>
        <div>
          <i className={classes.undo}>Undo</i>
          <span>Undo</span>
        </div>
        <div>
          <i className={classes.redo}>Redo</i>
          <span>Redo</span>
        </div>
      </div>
      <div className={classes.videoOptions}>
        <div onClick={showUploadWatermarkModal}>
          <i className={classes.watermark}>Watermark</i>
          <span>Watermark</span>
        </div>
        <div
          className={classnames(
            classes.highlightsContainer,
            !isAddHighlightModeActive && classes.disabled
          )}
          onClick={toggleHighlightsMenu}
          ref={highlightsMenuRef}
        >
          <div className={classes.overlay} />
          <svg
            width="19"
            height="19"
            viewBox="0 0 19 19"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M14.25 3.16659V2.37492C14.25 1.9395 13.8937 1.58325 13.4583 1.58325H3.95832C3.52291 1.58325 3.16666 1.9395 3.16666 2.37492V5.54159C3.16666 5.977 3.52291 6.33325 3.95832 6.33325H13.4583C13.8937 6.33325 14.25 5.977 14.25 5.54159V4.74992H15.0417V7.91659H7.12499V16.6249C7.12499 17.0603 7.48124 17.4166 7.91666 17.4166H9.49999C9.93541 17.4166 10.2917 17.0603 10.2917 16.6249V9.49992H16.625V3.16659H14.25Z"
              fill={HIGHLIGHT_COLORS[highlightColor.toLocaleUpperCase()][0]}
            />
          </svg>
          <span>Highlights</span>
          <div
            className={classnames(
              classes.highlightsMenu,
              isHighlightsMenuVisible && classes.show
            )}
          >
            <ul>
              <li
                style={{
                  backgroundColor: HIGHLIGHT_COLORS.RED[0],
                  borderColor: HIGHLIGHT_COLORS.RED[0],
                }}
                onClick={(event) => setActiveColor(event, 'red')}
                className={classnames(
                  highlightColor === 'red' && classes.active
                )}
              />
              <li
                style={{
                  backgroundColor: HIGHLIGHT_COLORS.GRAY[0],
                  borderColor: HIGHLIGHT_COLORS.GRAY[0],
                }}
                onClick={(event) => setActiveColor(event, 'gray')}
                className={classnames(
                  highlightColor === 'gray' && classes.active
                )}
              />
              <li
                style={{
                  backgroundColor: HIGHLIGHT_COLORS.PURPLE[0],
                  borderColor: HIGHLIGHT_COLORS.PURPLE[0],
                }}
                onClick={(event) => setActiveColor(event, 'purple')}
                className={classnames(
                  highlightColor === 'purple' && classes.active
                )}
              />
              <li
                style={{
                  backgroundColor: HIGHLIGHT_COLORS.BLUE[0],
                  borderColor: HIGHLIGHT_COLORS.BLUE[0],
                }}
                onClick={(event) => setActiveColor(event, 'blue')}
                className={classnames(
                  highlightColor === 'blue' && classes.active
                )}
              />
              <li
                style={{
                  backgroundColor: HIGHLIGHT_COLORS.ORANGE[0],
                  borderColor: HIGHLIGHT_COLORS.ORANGE[0],
                }}
                onClick={(event) => setActiveColor(event, 'orange')}
                className={classnames(
                  highlightColor === 'orange' && classes.active
                )}
              />
              <li
                style={{
                  backgroundColor: HIGHLIGHT_COLORS.YELLOW[0],
                  borderColor: HIGHLIGHT_COLORS.YELLOW[0],
                }}
                onClick={(event) => setActiveColor(event, 'yellow')}
                className={classnames(
                  highlightColor === 'yellow' && classes.active
                )}
              />
              <li
                style={{
                  backgroundColor: HIGHLIGHT_COLORS.GREEN[0],
                  borderColor: HIGHLIGHT_COLORS.GREEN[0],
                }}
                onClick={(event) => setActiveColor(event, 'green')}
                className={classnames(
                  highlightColor === 'green' && classes.active
                )}
              />
              <li
                style={{
                  backgroundColor: HIGHLIGHT_COLORS.PINK[0],
                  borderColor: HIGHLIGHT_COLORS.PINK[0],
                }}
                onClick={(event) => setActiveColor(event, 'pink')}
                className={classnames(
                  highlightColor === 'pink' && classes.active
                )}
              />
            </ul>
          </div>
        </div>
        <div onClick={showFileUploadModal}>
          <i className={classes.addLogo}>Add logo</i>
          <span>Add logo</span>
        </div>
      </div>
      <FileUploadModal
        show={isFileUploadModalVisible}
        onHide={hideFileUploadModal}
        uploadLogo
        fileId={video.id}
      />
      <FileUploadModal
        show={isUploadWatermarkModalVisible}
        onHide={hideUploadWatermarkModal}
        uploadWatermark
        fileId={video.id}
      />
    </div>
  );
}
