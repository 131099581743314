export default function formatTimeStamp(time, noDays) {
  if (noDays) {
    // eslint-disable-next-line no-param-reassign
    time = Math.floor(time);
  }

  let hours = 0;
  let minutes = 0;
  let seconds = 0;
  if (time > 3600) {
    hours = Math.floor(time / 3600);
  }

  if (hours) {
    minutes = Math.floor((time - hours * 3600) / 60);
  } else if (time > 60) {
    minutes = Math.floor(time / 60);
  }

  if (hours || minutes) {
    seconds = time - hours * 3600 - minutes * 60;
  } else {
    seconds = time;
  }

  if (noDays) {
    return `${hours.toString().length < 2 ? `0${hours}` : hours}:${
      minutes.toString().length < 2 ? `0${minutes}` : minutes
    }:${seconds.toString().length < 2 ? `0${seconds}` : seconds}`;
  }

  return `00:${hours.toString().length < 2 ? `0${hours}` : hours}:${
    minutes.toString().length < 2 ? `0${minutes}` : minutes
  }:${seconds.toString().length < 2 ? `0${seconds}` : seconds}`;
}
