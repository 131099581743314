export default function isOverlapping(elementRef, nodeList) {
  const rect1 = elementRef.current.getBoundingClientRect();
  for (let i = 0; i < nodeList.length; i += 1) {
    const rect2 = nodeList[i].getBoundingClientRect();
    const overlap = !(
      rect1.right < rect2.left ||
      rect1.left > rect2.right ||
      rect1.bottom < rect2.top ||
      rect1.top > rect2.bottom
    );
    if (overlap) {
      return true;
    }
  }

  return false;
}
