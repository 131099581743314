import React, { useState } from 'react';

import { useLocation, useHistory } from 'react-router-dom';
import { connect } from 'react-redux';
import * as Yup from 'yup';
import { Formik } from 'formik';

import { actions } from '../../resources/users';
import EmptyHeader from '../../components/EmptyHeader';
import FormField from '../../components/Form/FormField';
import StyledButton from '../../components/Form/StyledButton';

import classes from './styles.module.scss';

const ValidationSchema = Yup.object().shape({
  password: Yup.string()
    .required('Password is a required field')
    .min(8, 'Password must be at least 8 characters'),
  passwordConfirmation: Yup.string()
    .required('Password confirmation is a required field')
    .min(8, 'Password must be at least 8 characters')
    .oneOf([Yup.ref('password'), null], 'Passwords must match'),
});

function ChangePasswordPage({ changePasswordUser }) {
  const [successMessage, setSuccessMessage] = useState('');

  const history = useHistory();
  const location = useLocation();
  const params = new URLSearchParams(location.search);
  const token = params.get('token');
  return (
    <div className={classes.ChangePasswordPage}>
      <EmptyHeader />
      <div className={classes.formContainer}>
        <h2>Set new password</h2>
        <Formik
          initialValues={{
            password: '',
            passwordConfirmation: '',
          }}
          validationSchema={ValidationSchema}
          onSubmit={(values, { setFieldError }) => {
            setSuccessMessage('');
            if (!token) {
              return;
            }
            changePasswordUser({ password: values.password, token })
              .then((response) => {
                if (response.status !== 200) {
                  setFieldError(
                    'password',
                    response.body.message.toLowerCase()
                  );
                  return;
                }
                setSuccessMessage('your password has been changed');
              })
              .catch((error) => {
                setSuccessMessage('');
                console.log(error);
                setFieldError(
                  'password',
                  'reset password token is wrong or expired'
                );
              });
          }}
        >
          {({
            values,
            errors,
            touched,
            handleChange,
            handleBlur,
            handleSubmit,
            // setFieldValue,
            // isSubmitting,
          }) => (
            <form className={classes.registrationForm}>
              <FormField
                label="Password"
                type="password"
                onChange={handleChange('password')}
                onBlur={handleBlur('password')}
                value={values.password}
                isInvalid={touched.password && errors.password}
                errorMessage={errors.password}
                isValid={successMessage !== ''}
                successMessage={successMessage}
              />
              <FormField
                label="Confirm Password"
                type="password"
                onChange={handleChange('passwordConfirmation')}
                onBlur={handleBlur('passwordConfirmation')}
                value={values.passwordConfirmation}
                isInvalid={
                  touched.passwordConfirmation && errors.passwordConfirmation
                }
                errorMessage={errors.passwordConfirmation}
              />
              {successMessage === 'your password has been changed' ? (
                <StyledButton
                  style={{ alignSelf: 'center', marginTop: 22 }}
                  onClick={() => {
                    history.push('/login');
                  }}
                  label="Go to Log in"
                />
              ) : (
                <StyledButton
                  style={{ alignSelf: 'center', marginTop: 22 }}
                  onClick={handleSubmit}
                  label="Change Password"
                />
              )}
            </form>
          )}
        </Formik>
      </div>
    </div>
  );
}

export default connect(null, {
  changePasswordUser: actions.changePasswordUser,
})(ChangePasswordPage);
