import React, { useContext, useRef, useEffect } from 'react';

import { connect } from 'react-redux';
import * as Yup from 'yup';
import { Formik } from 'formik';
import Modal from 'react-bootstrap/Modal';

import { UiContext, FoldersContext } from '../../context/index';
import { actions } from '../../resources/folders';
import FormField from '../../components/Form/FormField';
import StyledButton from '../../components/Form/StyledButton';
import '../../assets/bootstrap-modal.css';
import classes from './styles.module.scss';

const ValidationSchema = Yup.object().shape({
  folderName: Yup.string().required('Folder name is a required field'),
});

function CreateFolderModal({ show, onHide, createFolder }) {
  const [, , , setFolderContentElement] = useContext(UiContext);
  const [currentOpenFolder] = useContext(FoldersContext);

  const inputRef = useRef();

  useEffect(() => {
    if (inputRef.current) {
      inputRef.current.focus();
    }
  }, [show]);

  return (
    <Modal
      show={show}
      onHide={onHide}
      size="md"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      className={classes.CreateFolderModal}
    >
      <Modal.Header closeButton className={classes.header}>
        New Folder
      </Modal.Header>

      <Formik
        initialValues={{
          folderName: '',
        }}
        validationSchema={ValidationSchema}
        onSubmit={(values, { setFieldError }) => {
          createFolder({
            ...values,
            parentFolderId: currentOpenFolder ? currentOpenFolder.id : null,
          })
            .then((response) => {
              setFieldError('folderName', response.statusText);
              setFolderContentElement({
                element: response.body,
                status: 'added',
              });
              onHide();
            })
            .catch((error) => {
              console.log(error);
              setFieldError('folderName', error.body.message);
            });
        }}
      >
        {({
          values,
          errors,
          touched,
          handleChange,
          handleBlur,
          handleSubmit,
          // setFieldValue,
          // isSubmitting,
        }) => (
          <>
            <Modal.Body className={classes.body}>
              <form onSubmit={handleSubmit}>
                <FormField
                  label="Folder name"
                  onChange={handleChange('folderName')}
                  onBlur={handleBlur('folderName')}
                  value={values.folderName}
                  isInvalid={touched.folderName && errors.folderName}
                  errorMessage={errors.folderName}
                  setRef={inputRef}
                />
              </form>
            </Modal.Body>

            <Modal.Footer className={classes.footer}>
              <StyledButton
                className={classes.button}
                onClick={onHide}
                label="Cancel"
                variant="outline"
              />
              <StyledButton
                className={classes.button}
                onClick={handleSubmit}
                label="Create"
              />
            </Modal.Footer>
          </>
        )}
      </Formik>
    </Modal>
  );
}

export default connect(null, {
  createFolder: actions.createFolder,
})(CreateFolderModal);
