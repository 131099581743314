/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
import React, { useContext, useRef } from 'react';

import { HighlightContext, VideoContext } from '../../../../../context/index';
import { HIGHLIGHT_COLORS } from '../../../../../constants/main';

import classes from './styles.module.scss';

export default function Highlight({ highlight, secondsToPixelsRatio }) {
  const highlightRef = useRef();

  const [
    ,
    setIsHighlightMenuVisible,
    ,
    setCurrentHighlight,
    ,
    setHighlightRef,
  ] = useContext(HighlightContext);

  const [, , isPresentationMode] = useContext(VideoContext);

  return (
    <>
      <div
        ishighlight="true"
        ref={highlightRef}
        className={classes.Highlight}
        style={{
          width:
            (highlight.endTime - highlight.startTime) * secondsToPixelsRatio,
          backgroundColor: HIGHLIGHT_COLORS[highlight.color.toUpperCase()][1],
          borderColor: HIGHLIGHT_COLORS[highlight.color.toUpperCase()][0],
        }}
        onClick={(event) => {
          console.log('CKICKED ON HIGHLIGHT');
          if (isPresentationMode) {
            return;
          }
          event.nativeEvent.stopImmediatePropagation();
          event.stopPropagation();
          setCurrentHighlight(highlight);
          setHighlightRef(highlightRef);
          setIsHighlightMenuVisible(true);
        }}
      >
        <div
          className={classes.threeDots}
          style={{
            borderColor: HIGHLIGHT_COLORS[highlight.color.toUpperCase()][0],
            left: `calc(50% - 12px)`,
          }}
        >
          <div className={classes.dotsContainer}>
            <div
              style={{
                backgroundColor:
                  HIGHLIGHT_COLORS[highlight.color.toUpperCase()][0],
              }}
            />
            <div
              style={{
                backgroundColor:
                  HIGHLIGHT_COLORS[highlight.color.toUpperCase()][0],
              }}
            />
            <div
              style={{
                backgroundColor:
                  HIGHLIGHT_COLORS[highlight.color.toUpperCase()][0],
              }}
            />
          </div>
        </div>
      </div>
    </>
  );
}
