import React from 'react';

import moment from 'moment';
import classnames from 'classnames';

import DropDownMenu from '../DropDownMenu';
import classes from './styles.module.scss';

export default function Folder({
  onClick,
  folder,
  index,
  folderTitleRef,
  folderCreatedAtRef,
  currentViewMode,
  showShareEntityModal,
  openFolder,
  showRenameFolderModal,
  deleteFolder,
  setFolderContentElement,
  showMoveFileModal,
  shared,
  getSharedEntityLink,
}) {
  return (
    <div
      onClick={onClick}
      className={classnames(
        classes.folder,
        currentViewMode === 'grid' && classes.grid,
        (currentViewMode === 'list' || currentViewMode === null) && classes.list
      )}
      onContextMenu={(event) => {
        if (currentViewMode === 'list' || currentViewMode === null) {
          return;
        }
        event.preventDefault();
        event.currentTarget.querySelector('.dropdown div').click();
      }}
    >
      <h1 ref={index === 0 ? folderTitleRef : null}>{folder.name}</h1>
      <span
        ref={index === 0 ? folderCreatedAtRef : null}
        className={classnames(
          classes.createdAt,
          index === 0 && classes.firstOne
        )}
      >
        {moment(folder.createdAt).format('DD.MM.YYYY')}
      </span>
      <span
        className={classnames(
          classes.updatedAt,
          index === 0 && classes.firstOne
        )}
      >
        {moment(folder.updatedAt).format('DD.MM.YYYY')}
      </span>
      <div className={classes.folderMenu}>
        <DropDownMenu
          shared={shared}
          onShare={() => showShareEntityModal(folder)}
          onGetShareableLink={() => getSharedEntityLink(folder)}
          onOpen={() => openFolder(folder)}
          onRename={() => {
            showRenameFolderModal(folder);
          }}
          onRemove={() => {
            deleteFolder(folder).then(() => {
              setFolderContentElement({
                element: null,
                status: 'moved',
              });
            });
          }}
          onMove={() => showMoveFileModal(folder)}
          hidden={currentViewMode === 'grid'}
          folderMenu
        />
      </div>
    </div>
  );
}
