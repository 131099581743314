import React, {
  useEffect,
  useState,
  useLayoutEffect,
  useRef,
  useCallback,
} from 'react';

import axios from 'axios';
import { useLocation, useHistory } from 'react-router-dom';

import { REACT_APP_API_URL, REACT_APP_STATIC_URL } from '../../constants/main';
import EditFile from '../../components/FileManager/EditFile';

import classes from './styles.module.scss';

export default function PresentationPage() {
  const [video, setVideo] = useState(null);
  const [presentationHighlights, setPresentationHighlights] = useState([]);
  const [presentationThreads, setPresentationThreads] = useState([]);

  const query = new URLSearchParams(useLocation().search);
  const history = useHistory();

  const fetchHighlights = () => {
    axios({
      method: 'GET',
      url: `${REACT_APP_API_URL}/highlights`,
      params: {
        fileId: query.get('videoId'),
      },
      headers: {
        authorization: query.get('token')
          ? `${query.get('token')}`
          : JSON.parse(localStorage.getItem('authData')).accessToken,
      },
    })
      .then((response) => {
        setPresentationHighlights(response.data);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const fetchThreads = () => {
    axios({
      method: 'GET',
      url: `${REACT_APP_API_URL}/threads`,
      params: {
        fileId: query.get('videoId'),
      },
      headers: {
        authorization: query.get('token')
          ? `${query.get('token')}`
          : JSON.parse(localStorage.getItem('authData')).accessToken,
      },
    })
      .then((response) => {
        setPresentationThreads(response.data);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  useEffect(() => {
    if (!query.get('videoId')) {
      history.replace('/');
    } else {
      axios({
        method: 'GET',
        url: `${REACT_APP_API_URL}/files/${query.get('videoId')}`,
        headers: {
          authorization: query.get('token')
            ? `${query.get('token')}`
            : JSON.parse(localStorage.getItem('authData')).accessToken,
        },
      })
        .then((response) => {
          setVideo(response.data);
        })
        .catch((error) => {
          console.log(error);
        });
    }
  }, []);

  useEffect(() => {
    if (video) {
      fetchHighlights();
      fetchThreads();
      document.querySelector('video').currentTime = query.get('currentTime');
    }
  }, [video]);

  const connectionIdx = useRef(0);
  const messageIdx = useRef(0);

  const addConnection = useCallback((connection) => {
    connectionIdx.current += 1;
    // eslint-disable-next-line no-param-reassign
    connection.connectionId = connectionIdx.current;

    connection.addEventListener('message', function (event) {
      messageIdx.current += 1;
      const data = JSON.parse(event.data);
      // const logString = `Message ${messageIdx.current} from connection #${connection.connectionId}: ${data}`;
      // console.log(logString);
      console.log(data);
      connection.send(`Received message ${messageIdx.current}`);

      if (data.message === 'startPlaying') {
        document.querySelector('video').play();
      }

      if (data.message === 'pause') {
        document.querySelector('video').pause();
      }

      if (data.message === 'seek') {
        document.querySelector('video').currentTime = data.payload;
      }

      if (data.message === 'fetchPresentationHighlights') {
        fetchHighlights();
      }

      if (data.message === 'fetchPresentationThreads') {
        fetchThreads();
      }
    });

    connection.addEventListener('close', function (event) {
      console.log(
        `Connection #${connection.connectionId} closed, reason = ${event.reason}, message = ${event.message}`
      );
    });
  }, []);

  useLayoutEffect(() => {
    document.addEventListener('DOMContentLoaded', function () {
      try {
        if (navigator.presentation.receiver) {
          navigator.presentation.receiver.connectionList.then((list) => {
            list.connections.map((connection) => addConnection(connection));
            list.addEventListener('connectionavailable', function (event) {
              addConnection(event.connection);
            });
          });
        }
      } catch (e) {
        console.log(e);
      }
    });
  }, [video]);

  return (
    <div className={classes.PresentationPage}>
      <header className={classes.presentationHeader}>
        <div
          className={classes.presentationLogo}
          style={{
            backgroundImage:
              video &&
              video.logoPath &&
              `url(${REACT_APP_STATIC_URL}${video.logoPath})`.replace(
                /\\/g,
                '/'
              ),
          }}
        />
      </header>
      {video && (
        <EditFile
          video={video}
          accessToken={query.get('token')}
          presentationHighlights={presentationHighlights}
          presentationThreads={presentationThreads}
        />
      )}
    </div>
  );
}
