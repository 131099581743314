import React, { useContext } from 'react';

import classnames from 'classnames';

import classes from './styles.module.scss';
import { UiContext } from '../../../../context/index';

export default function Filters({
  isPresentationMode,
  isSharedVideoMode,
  onApplyFilter,
  video,
  isDisabled,
}) {
  const [, , , , , , , , , , activeFilter, setActiveFilter] = useContext(
    UiContext
  );

  return (
    <div className={classes.Filters}>
      <div className={classes.gaussFilters}>
        <div
          className={classnames(
            classes.filter,
            classes.gauss,
            activeFilter === 'gauss' && classes.active
          )}
          onClick={() => {
            if (
              isPresentationMode ||
              isSharedVideoMode ||
              activeFilter === 'gauss' ||
              !video.gaussFilteredPath ||
              isDisabled
            ) {
              return;
            }
            setActiveFilter('gauss');
            onApplyFilter('gauss');
          }}
        >
          <div className={classes.preview} />
          <div className={classes.title}>
            <h1>Heat Map</h1>
          </div>
        </div>
        <div
          className={classnames(
            classes.filter,
            classes.gaussGray,
            activeFilter === 'gaussGray' && classes.active
          )}
          onClick={() => {
            if (
              isPresentationMode ||
              isSharedVideoMode ||
              activeFilter === 'gaussGray' ||
              !video.gaussGreyFilteredPath ||
              isDisabled
            ) {
              return;
            }
            setActiveFilter('gaussGray');
            onApplyFilter('gaussGray');
          }}
        >
          <div className={classes.preview} />
          <div className={classes.title}>
            <h1>Opacity Map</h1>
          </div>
        </div>
        <div
          className={classnames(
            classes.filter,
            classes.origin,
            activeFilter === 'origin' && classes.active
          )}
          onClick={() => {
            if (
              isPresentationMode ||
              isSharedVideoMode ||
              activeFilter === 'origin' ||
              isDisabled
            ) {
              return;
            }
            setActiveFilter('origin');
            onApplyFilter('origin');
          }}
        >
          <div className={classes.preview} />
          <div className={classes.title}>
            <h1>Original Video</h1>
          </div>
        </div>
      </div>
    </div>
  );
}
