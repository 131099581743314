import React, { useState, useEffect } from 'react';

import moment from 'moment';

import CommentMenu from './CommentMenu';
import classes from './styles.module.scss';

export default function Comment({ id, author, date, text, onDelete, onEdit }) {
  const [isEditMode, setIsEditMode] = useState(false);
  const [commentText, setCommentText] = useState('');

  const enableEditMode = () => {
    setIsEditMode(true);
  };

  const editComment = () => {
    onEdit({ id, text: commentText });
  };

  useEffect(() => {
    setCommentText(text);
  }, [text]);

  return (
    <div className={classes.Comment}>
      <header className={classes.commentHeader}>
        <div className={classes.avatar}>
          <span>{author[0]}</span>
        </div>
        <div className={classes.details}>
          <h1>{author}</h1>
          <span className={classes.date}>{moment(date).calendar()}</span>
        </div>
        {author === JSON.parse(localStorage.getItem('authData'))?.email && (
          <div className={classes.commentMenu}>
            <CommentMenu onDelete={onDelete} onEdit={enableEditMode} />
          </div>
        )}
      </header>
      <main className={classes.commentMain}>
        {isEditMode ? (
          <footer className={classes.addCommentBox}>
            <textarea
              value={commentText}
              onChange={(event) => setCommentText(event.target.value)}
            />
            <i
              className={classes.sendComment}
              onClick={() => {
                editComment();
                setIsEditMode(false);
              }}
            >
              Send comment
            </i>
          </footer>
        ) : (
          <p>{text}</p>
        )}
      </main>
    </div>
  );
}
