/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
import React, { useEffect, useState, useContext } from 'react';

import { connect } from 'react-redux';
import useForceUpdate from 'use-force-update';
import classnames from 'classnames';

import { HighlightContext } from '../../../../../context';
import { actions } from '../../../../../resources/highlights';
import { HIGHLIGHT_COLORS } from '../../../../../constants/main';

import classes from './styles.module.scss';

function getLeftPosition(selectionRef, selectionWidth) {
  const selectionCoords = selectionRef?.current?.getBoundingClientRect();
  const chartCoords = selectionRef?.current?.parentNode.getBoundingClientRect();

  console.log(chartCoords.left - selectionCoords.left);

  const correction =
    chartCoords.left - selectionCoords.left > 0
      ? chartCoords.left - selectionCoords.left
      : 0;

  return (
    selectionRef?.current?.getBoundingClientRect().left +
    (selectionWidth + correction) / 2 -
    136
  );
}

function CreateHighlight({
  selectionRef,
  selectionWidth,
  show,
  currentColor,
  setCurrentColor,
  deleteSelection,
  selectionLeft,
  createHighlight,
  secondsToPixelsRatio,
  video,
  chartTitle,
}) {
  const [isEnoughSpace, setIsEnoughSpace] = useState(true);
  const [comment, setComment] = useState('');

  const [, , , , , , , setIsAddHighlightModeActive] = useContext(
    HighlightContext
  );

  const forceUpdate = useForceUpdate();

  const changeColor = (color) => {
    setCurrentColor(color);
  };

  useEffect(() => {
    if (
      window.innerHeight - selectionRef.current.getBoundingClientRect().bottom <
      160
    ) {
      setIsEnoughSpace(false);
    }
  }, []);

  const changePositionOnScroll = () => {
    if (show) {
      forceUpdate();
    }
  };

  useEffect(() => {
    window.addEventListener('scroll', changePositionOnScroll);
    return () => {
      window.removeEventListener('scroll', changePositionOnScroll);
    };
  }, [show]);

  const saveHighlight = () => {
    createHighlight({
      fileId: video.id,
      startTime:
        selectionLeft / secondsToPixelsRatio > 0
          ? selectionLeft / secondsToPixelsRatio
          : 0,
      endTime:
        selectionWidth / secondsToPixelsRatio +
        selectionLeft / secondsToPixelsRatio,
      comment,
      color: currentColor,
      chart: chartTitle,
    });
    setComment('');
    setIsAddHighlightModeActive(false);
    deleteSelection();
  };

  return (
    <>
      <div
        className={classes.overlay}
        style={{ display: show ? 'block' : 'none' }}
        onClick={(event) => {
          event.nativeEvent.stopImmediatePropagation();
          event.stopPropagation();
          deleteSelection();
        }}
      />
      <div
        className={classnames(
          classes.CreateHighlight,
          show && classes.show,
          !isEnoughSpace && classes.top
        )}
        onClick={(event) => {
          event.nativeEvent.stopImmediatePropagation();
          event.stopPropagation();
        }}
        style={{
          left: selectionRef.current
            ? getLeftPosition(selectionRef, selectionWidth)
            : '',
          top: isEnoughSpace
            ? selectionRef?.current?.getBoundingClientRect().bottom + 11
            : selectionRef?.current?.getBoundingClientRect().top - 160,
        }}
      >
        <div
          className={classes.threeDots}
          style={{
            borderColor: HIGHLIGHT_COLORS[currentColor.toUpperCase()][0],
          }}
        >
          <div className={classes.dotsContainer}>
            <div
              style={{
                backgroundColor:
                  HIGHLIGHT_COLORS[currentColor.toUpperCase()][0],
              }}
            />
            <div
              style={{
                backgroundColor:
                  HIGHLIGHT_COLORS[currentColor.toUpperCase()][0],
              }}
            />
            <div
              style={{
                backgroundColor:
                  HIGHLIGHT_COLORS[currentColor.toUpperCase()][0],
              }}
            />
          </div>
        </div>
        <div className={classes.comment}>
          <textarea
            maxLength={255}
            value={comment}
            onChange={(event) => setComment(event.target.value)}
          />
        </div>
        <div className={classes.colors}>
          <ul>
            <li
              className={classnames(
                classes.green,
                currentColor === 'green' && classes.active
              )}
              onClick={() => changeColor('green')}
            />
            <li
              className={classnames(
                classes.orange,
                currentColor === 'orange' && classes.active
              )}
              onClick={() => changeColor('orange')}
            />
            <li
              className={classnames(
                classes.red,
                currentColor === 'red' && classes.active
              )}
              onClick={() => changeColor('red')}
            />
            <li
              className={classnames(
                classes.gray,
                currentColor === 'gray' && classes.active
              )}
              onClick={() => changeColor('gray')}
            />
            <li
              className={classnames(
                classes.purple,
                currentColor === 'purple' && classes.active
              )}
              onClick={() => changeColor('purple')}
            />
            <li
              className={classnames(
                classes.blue,
                currentColor === 'blue' && classes.active
              )}
              onClick={() => changeColor('blue')}
            />
            <li
              className={classnames(
                classes.yellow,
                currentColor === 'yellow' && classes.active
              )}
              onClick={() => changeColor('yellow')}
            />
            <li
              className={classnames(
                classes.pink,
                currentColor === 'pink' && classes.active
              )}
              onClick={() => changeColor('pink')}
            />
          </ul>
        </div>
        <div className={classes.buttons}>
          <button
            type="button"
            onClick={() => {
              deleteSelection();
              setIsAddHighlightModeActive(false);
            }}
          >
            Delete
          </button>
          <button onClick={saveHighlight} type="button">
            Save
          </button>
        </div>
      </div>
    </>
  );
}

export default connect(null, {
  createHighlight: actions.createHighlight,
})(CreateHighlight);
