import jwtDecode from 'jwt-decode';

const AUTH_STORAGE_KEY = 'authData';
const ACCESS_TOKEN_DATA_REGEXP = /^[^.]+\.([^\s]+)[^.]*$/i;

export const getAuthData = () =>
  JSON.parse(localStorage.getItem(AUTH_STORAGE_KEY) || '{}');

export const hasAuthData = () => {
  const authData = getAuthData();

  if (!authData.userId) {
    return false;
  }

  const decoded = jwtDecode(authData.accessToken);
  const currentDate = new Date();

  if (decoded.exp * 1000 < currentDate.getTime()) {
    return false;
  }
  return true;
};

export const saveAuthData = ({ userId, accessToken, email, refreshToken }) =>
  localStorage.setItem(
    AUTH_STORAGE_KEY,
    JSON.stringify({ userId, email, accessToken, refreshToken })
  );

export const clearAuthData = () => localStorage.removeItem(AUTH_STORAGE_KEY);

/**
 * Get data saved in token (userId, role..)
 * @returns {object|null}
 */
export const getTokenData = () => {
  const { accessToken } = getAuthData();

  if (!accessToken || !ACCESS_TOKEN_DATA_REGEXP.test(accessToken)) {
    return null;
  }

  const accessTokenData = accessToken.replace(ACCESS_TOKEN_DATA_REGEXP, '$1');
  return JSON.parse(atob(accessTokenData));
};
