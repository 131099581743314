import React from 'react';

import classnames from 'classnames';

import classes from './styles.module.scss';

export default function StyledButton({
  label,
  disabled,
  onClick,
  style,
  variant,
}) {
  return (
    <button
      type="button"
      className={classnames(
        classes.StyledButton,
        variant && variant === 'outline' && classes.outline
      )}
      disabled={disabled}
      onClick={onClick}
      style={style}
    >
      {label}
    </button>
  );
}
