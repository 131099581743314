import React, { useEffect, useState } from 'react';

import { BrowserRouter, Route, Switch, Redirect } from 'react-router-dom';
import { LastLocationProvider } from 'react-router-last-location';

import { UiContext, HighlightContext } from '../context/index';
import { hasAuthData } from '../helpers/authStorage';

import Layout from '../components/Layout';
import ExamplePage from '../pages/ExamplePage';
// import PrivateRoute from '../containers/PrivateRoute';
import RegistrationPage from '../pages/RegistrationPage';
import LoginPage from '../pages/LoginPage';
import ForgotPasswordPage from '../pages/ForgotPasswordPage';
import ChangePasswordPage from '../pages/ChangePasswordPage';
import HomePage from '../pages/HomePage';
import ShareLinkPage from '../pages/ShareLinkPage';
import PresentationPage from '../pages/PresentationPage';

import './global.scss';

const Routes = () => {
  const [searchResults, setSearchResults] = useState(['empty']);
  const [isSharedWithMeTabActive, setIsSharedWithMeTabActive] = useState(false);
  const [mainChartsRef, setMainChartsRef] = useState(null);
  const [isSideBarExpanded, setIsSideBarExpanded] = useState(false);
  const [folderContentElement, setFolderContentElement] = useState({
    element: null,
    status: '',
  });
  const [isHighlightMenuVisible, setIsHighlightMenuVisible] = useState(false);
  const [currentHighlight, setCurrentHighlight] = useState(null);
  const [highlightRef, setHighlightRef] = useState(null);
  const [isAddHighlightModeActive, setIsAddHighlightModeActive] = useState(
    false
  );
  const [isSpinnerVisible, setIsSpinnerVisible] = useState(false);
  const [activeFilter, setActiveFilter] = useState('origin');

  useEffect(() => {
    document.body.setAttribute('id', 'scroll');
  }, []);

  return (
    <BrowserRouter>
      <LastLocationProvider>
        <UiContext.Provider
          value={[
            isSideBarExpanded,
            setIsSideBarExpanded,
            folderContentElement,
            setFolderContentElement,
            isSharedWithMeTabActive,
            setIsSharedWithMeTabActive,
            mainChartsRef,
            setMainChartsRef,
            isSpinnerVisible,
            setIsSpinnerVisible,
            activeFilter,
            setActiveFilter,
          ]}
        >
          <HighlightContext.Provider
            value={[
              isHighlightMenuVisible,
              setIsHighlightMenuVisible,
              currentHighlight,
              setCurrentHighlight,
              highlightRef,
              setHighlightRef,
              isAddHighlightModeActive,
              setIsAddHighlightModeActive,
            ]}
          >
            <Switch>
              {/* <PrivateRoute path="/dashboard" component={DashboardRoutes} /> */}
              {/* <Route exact path="/login" component={LoginPage} /> */}
              {/* <Route exact path="/forgot-password" component={ForgotPasswordPage} /> */}
              {/* <Route exact path="/reset-password" component={ResetPasswordPage} /> */}
              {/* <Route exact path="/error" component={ErrorPage} /> */}

              <Route
                path="/home"
                exact
                render={() =>
                  hasAuthData() ? (
                    <Layout
                      searchResults={searchResults}
                      setSearchResults={setSearchResults}
                    >
                      <HomePage
                        searchResults={searchResults}
                        setSearchResults={setSearchResults}
                      />
                    </Layout>
                  ) : (
                    <Redirect
                      to={{
                        pathname: '/login',
                      }}
                    />
                  )
                }
              />
              <Route
                path="/"
                exact
                render={() =>
                  hasAuthData() ? (
                    <Layout
                      searchResults={searchResults}
                      setSearchResults={setSearchResults}
                    >
                      <HomePage
                        searchResults={searchResults}
                        setSearchResults={setSearchResults}
                      />
                    </Layout>
                  ) : (
                    <Redirect
                      to={{
                        pathname: '/login',
                      }}
                    />
                  )
                }
              />

              <Route exact path="/" component={ExamplePage} />
              <Route exact path="/registration" component={RegistrationPage} />
              <Route exact path="/login" component={LoginPage} />
              <Route
                exact
                path="/forgot-password"
                component={ForgotPasswordPage}
              />
              <Route
                exact
                path="/change-password"
                component={ChangePasswordPage}
              />
              <Route
                exact
                path="/shared/:entityType/:link"
                render={() =>
                  hasAuthData() ? (
                    <ShareLinkPage />
                  ) : (
                    <Redirect
                      to={{
                        pathname: '/login',
                      }}
                    />
                  )
                }
              />
              <Route
                exact
                path="/presentation"
                render={() => (
                  <Layout hidden>
                    <PresentationPage />
                  </Layout>
                )}
              />
            </Switch>
          </HighlightContext.Provider>
        </UiContext.Provider>
      </LastLocationProvider>
    </BrowserRouter>
  );
};

export default Routes;
