import React, { useContext } from 'react';

import classnames from 'classnames';
import { UiContext } from '../../context';

import classes from './styles.module.scss';

export default function Spinner() {
  const [, , , , , , , , isSpinnerVisible] = useContext(UiContext);
  return (
    <div
      className={classnames(
        classes.spinnerContainer,
        isSpinnerVisible && classes.visible
      )}
    >
      <div className={classes.ldsDefault}>
        <div />
        <div />
        <div />
        <div />
        <div />
        <div />
        <div />
        <div />
        <div />
        <div />
        <div />
        <div />
      </div>
    </div>
  );
}
