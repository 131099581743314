import React, { useState } from 'react';

import classnames from 'classnames';

import ShareEntityModal from '../../../../modals/ShareEntityModal';
import PopupMessage from '../../PopupMessage';

import classes from './styles.module.scss';

export default function RightMenu({
  showCommentsSideBar,
  video,
  togglePresentation,
  getSharedEntityLink,
  isSharedVideoMode,
}) {
  const [isShareEntityModalVisible, setIsShareEntityModalVisible] = useState(
    false
  );
  const [isPopupMessageVisible, setIsPopupMessageVisible] = useState(false);
  const [popupMessage, setPopupMessage] = useState('');

  // const [, , , setIsPresentationMode] = useContext(VideoContext);

  const showShareEntityModal = () => {
    setIsShareEntityModalVisible(true);
  };

  const hideShareEntityModal = () => {
    setIsShareEntityModalVisible(false);
  };

  const showPopupMessage = (message) => {
    setPopupMessage(message);
    setIsPopupMessageVisible(true);
  };

  const hidePopupMessage = () => {
    setIsPopupMessageVisible(false);
  };

  return (
    <div
      className={classnames(
        classes.RightMenu,
        isSharedVideoMode && classes.sharedVideo
      )}
    >
      <div onClick={showCommentsSideBar} className={classes.button}>
        <i className={classes.addComment}>Add comment</i>
        <span>Add comment</span>
      </div>
      {!isSharedVideoMode && (
        <>
          <div
            className={classes.button}
            onClick={() => getSharedEntityLink(video)}
          >
            <i className={classes.shareableLink}>Shareable link</i>
            <span>Shareable link</span>
          </div>
          <div className={classes.button} onClick={showShareEntityModal}>
            <i className={classes.share}>Share</i>
            <span>Share</span>
          </div>
          <div className={classes.button} onClick={togglePresentation}>
            <i className={classes.presentation}>Presentation</i>
            <span>Presentation</span>
          </div>
        </>
      )}
      <ShareEntityModal
        show={isShareEntityModalVisible}
        onHide={hideShareEntityModal}
        entity={video}
        showPopupMessage={showPopupMessage}
      />
      <PopupMessage
        show={isPopupMessageVisible}
        onHide={hidePopupMessage}
        message={popupMessage}
      />
    </div>
  );
}
