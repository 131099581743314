/* eslint-disable no-plusplus */
/* eslint-disable consistent-return */
import React, { useContext, useEffect, useState, useRef } from 'react';

import axios from 'axios';
import classnames from 'classnames';

import formatTimeStamp from '../../../../helpers/formatTimeStamp';
import { REACT_APP_API_URL } from '../../../../constants/main';
import { VideoContext, UiContext } from '../../../../context/index';
import classes from './styles.module.scss';
import useDraggable from '../../../../helpers/useDraggable';

let enableAnimationTimer;
let getFramesTimer;

export default function SceneLine({
  zoomValue,
  setZoomValue,
  setIsZoomBarDisabled,
  currentTime,
  setCurrentTime,
  threads,
  showCommentsSideBar,
  secondsToPixelsRatio,
  setSecondsToPixelsRatio,
  videoDuration,
  setVideoDuration,
  setRef,
  presentationThreads,
  videoUrl,
  video,
}) {
  const [isCursorAnimationDisabled, setIsCursorAnimationDisabled] = useState(
    false
  );
  const [cursorVerticalLinePosition, setCursorVerticalLinePosition] = useState(
    6
  );
  const [frames, setFrames] = useState([]);
  const [timeIntervals, setTimeIntervals] = useState([]);
  const [verticalLineHeight, setVerticalLineHeight] = useState(10);
  const [skipTimeMarkValue, setSkipTimeMarkValue] = useState(10);
  // const [framesRatio, setFramesRatio] = useState(80);

  const [, videoRef, isPresentationMode] = useContext(VideoContext);
  const [isSideBarExpanded, , , , , , mainChartsRef] = useContext(UiContext);

  const cursorRef = useRef();

  const { xPosition } = useDraggable(cursorRef, setIsCursorAnimationDisabled);

  useEffect(() => {
    if (mainChartsRef && mainChartsRef.current) {
      setVerticalLineHeight(
        mainChartsRef.current.offsetHeight + setRef.current.offsetHeight - 19
      );
    }
  }, [zoomValue, mainChartsRef?.current?.offsetHeight]);

  useEffect(() => {
    if (setRef.current && videoDuration) {
      let startingRatio =
        (setRef.current.clientWidth - 17) / Math.ceil(videoDuration);
      startingRatio =
        startingRatio < 10 && !isPresentationMode ? 10 : startingRatio;
      const finalRatio = 160;
      const percentWeight = (finalRatio - startingRatio) / 100;
      setSecondsToPixelsRatio(
        startingRatio + percentWeight * zoomValue <= finalRatio
          ? startingRatio + percentWeight * zoomValue
          : finalRatio
      );
      if (startingRatio >= finalRatio) {
        setZoomValue(100);
        setIsZoomBarDisabled(true);
      }

      if (isPresentationMode && startingRatio <= 20) {
        // console.log('STARTING RATIO', startingRatio);
        const timeMarksNumber = Math.floor(
          (setRef.current.clientWidth - 17) / 85
        );
        setSkipTimeMarkValue(Math.ceil(videoDuration / timeMarksNumber));
        // console.log(Math.ceil(videoDuration / timeMarksNumber));
      }
    }
  }, [videoDuration, zoomValue]);

  useEffect(() => {
    if (isPresentationMode) {
      return;
    }
    const xCorrection = isSideBarExpanded ? 270 : 91;
    const xPos =
      xPosition < xCorrection
        ? 0
        : (xPosition + setRef.current.scrollLeft - xCorrection) /
          secondsToPixelsRatio;
    if (xPos > videoDuration) {
      return;
    }
    setCurrentTime(xPos);
  }, [xPosition]);

  const disableCursorAnimation = () => {
    setIsCursorAnimationDisabled(true);
  };

  const enableCursorAnimation = () => {
    setIsCursorAnimationDisabled(false);
  };

  const moveCursorOnClick = (event) => {
    if (isPresentationMode) {
      return;
    }
    const xCorrection = isSideBarExpanded ? 270 : 91;
    const xPos =
      event.pageX < xCorrection
        ? 0
        : (event.pageX + setRef.current.scrollLeft - xCorrection) /
          secondsToPixelsRatio;
    if (xPos > videoDuration) {
      return;
    }
    videoRef.current.pause();
    setTimeout(() => {
      setCurrentTime(xPos);
    }, 1);
    setTimeout(() => {
      enableCursorAnimation();
    }, 200);
  };

  useEffect(() => {
    if (setRef.current && videoRef.current) {
      if (
        currentTime * secondsToPixelsRatio >
          setRef.current.clientWidth + setRef.current.scrollLeft &&
        !videoRef.current.paused
      ) {
        setTimeout(() => {
          enableCursorAnimation();
        }, 100);
      }
    }

    if (videoRef.current && videoRef.current.paused) {
      videoRef.current.currentTime = currentTime;
    }
  }, [currentTime]);

  useEffect(() => {
    setCursorVerticalLinePosition(
      currentTime * secondsToPixelsRatio + 6 - setRef.current.scrollLeft
    );
  });

  const enableCursorAnimationOnTimeout = (time = 200) => {
    setTimeout(() => {
      enableCursorAnimation();
    }, time);
  };

  const moveVerticalLineOnScroll = () => {
    disableCursorAnimation();
    const coords = cursorRef.current.getBoundingClientRect();
    const xCorrection = isSideBarExpanded ? 270 : 91;

    setCursorVerticalLinePosition(coords.left + 6 - xCorrection);
    enableCursorAnimation();
  };

  const moveVerticalLineOnZoom = () => {
    const coords = cursorRef.current.getBoundingClientRect();
    const xCorrection = isSideBarExpanded ? 270 : 91;
    setCursorVerticalLinePosition(coords.left - xCorrection + 6);
  };

  useEffect(() => {
    if (videoRef.current) {
      videoRef.current.addEventListener('seeking', disableCursorAnimation);
      videoRef.current.addEventListener('playing', enableCursorAnimation);
      videoRef.current.addEventListener(
        'pause',
        enableCursorAnimationOnTimeout
      );
      videoRef.current.addEventListener(
        'seeked',
        enableCursorAnimationOnTimeout
      );
    }

    if (setRef.current) {
      setRef.current.addEventListener('scroll', moveVerticalLineOnScroll);
    }

    return () => {
      if (videoRef.current) {
        videoRef.current.removeEventListener('seeking', disableCursorAnimation);
        videoRef.current.removeEventListener('playing', enableCursorAnimation);
        videoRef.current.removeEventListener(
          'pause',
          enableCursorAnimationOnTimeout
        );
        videoRef.current.removeEventListener(
          'seeked',
          enableCursorAnimationOnTimeout
        );
      }
      if (setRef.current) {
        setRef.current.removeEventListener('scroll', moveVerticalLineOnScroll);
      }
    };
  }, [isSideBarExpanded]);

  useEffect(() => {
    if (zoomValue === 0) {
      setRef.current.scroll({ left: 0 });
    }

    disableCursorAnimation();
    setTimeout(() => {
      moveVerticalLineOnZoom();
    }, 0);

    clearTimeout(enableAnimationTimer);

    enableAnimationTimer = setTimeout(() => {
      enableCursorAnimation();
    }, 200);
  }, [zoomValue]);

  const updateCurrentTime = () => {
    if (videoRef.current) {
      setCurrentTime(videoRef.current.currentTime);
    }
  };

  const openThread = (time) => {
    if (isPresentationMode) {
      return;
    }
    showCommentsSideBar(time);
  };

  useEffect(() => {
    if (videoRef.current) {
      videoRef.current.addEventListener('timeupdate', updateCurrentTime);
    }

    return () => {
      if (videoRef.current) {
        videoRef.current.removeEventListener('timeupdate', updateCurrentTime);
      }
    };
  }, []);

  /*  useEffect(() => {
    console.log('zoom value', zoomValue);
  }, [zoomValue]); */

  useEffect(() => {
    if (videoUrl) {
      const videoElem = document.createElement('video');
      videoElem.style.position = 'absolute';
      videoElem.style.top = '-1000px';
      const source = document.createElement('source');
      source.src = videoUrl;
      videoElem.appendChild(source);
      videoElem.addEventListener('loadedmetadata', () => {
        setVideoDuration(videoElem.duration);
      });

      return () => {
        // document.body.removeChild(videoElem);
        videoElem.remove();
      };
    }
  }, [videoUrl]);

  useEffect(() => {
    const timeIntervalsArr = [];
    // eslint-disable-next-line no-plusplus
    for (let i = 0; i <= Math.ceil(videoDuration); i++) {
      timeIntervalsArr.push(
        <div
          key={i}
          className={classnames(
            classes.line,
            i % skipTimeMarkValue === 0 && i !== 0 && classes.highlight
          )}
          style={{
            marginRight: secondsToPixelsRatio - 1,
            visibility:
              i % skipTimeMarkValue && skipTimeMarkValue !== 10
                ? 'hidden'
                : 'visible',
          }}
        >
          {i % skipTimeMarkValue === 0 && (
            <div className={classes.timeStamp}>{formatTimeStamp(i)}</div>
          )}
          {!presentationThreads &&
            threads.find((thread) => thread.timeStamp === i) && (
              <div
                className={classes.threadMark}
                // style={{ left: i === 1 ? -114 : -4 }}
                onClick={(event) => {
                  event.stopPropagation();
                  openThread(i);
                }}
              />
            )}
          {presentationThreads &&
            presentationThreads.find((thread) => thread.timeStamp === i) && (
              <div
                className={classes.threadMark}
                // style={{ left: i === 0 ? -4 : '' }}
                onClick={(event) => {
                  event.stopPropagation();
                  openThread(i);
                }}
              />
            )}
        </div>
      );
    }
    setTimeIntervals(timeIntervalsArr);
  }, [secondsToPixelsRatio, threads, presentationThreads]);

  useEffect(() => {
    if (!video.climax) {
      return;
    }
    clearTimeout(getFramesTimer);
    getFramesTimer = setTimeout(() => {
      const framesArr = [];
      const framesCount = Math.ceil(
        (videoDuration * secondsToPixelsRatio) / 80
      );
      const promises = [];

      for (let i = 0; i < framesCount; i += 1) {
        const timeMark = Math.floor((i * 80) / secondsToPixelsRatio);
        const frameCount = Math.floor(
          video.climax.length * (timeMark / videoDuration)
        );
        const response = axios.get(
          `${REACT_APP_API_URL}/files/getUrl?key=${video.framesPath}/${
            frameCount + 1
          }.jpg`,
          {
            headers: {
              authorization: `${
                JSON.parse(localStorage.getItem('authData')).accessToken
              }`,
            },
          }
        );
        promises.push(response);
      }

      Promise.all(promises)
        .then((values) => {
          for (let i = 0; i < framesCount; i++) {
            framesArr.push(
              <div
                key={i}
                className={classes.frame}
                style={{ backgroundImage: `url(${values[i].data.url})` }}
              />
            );
          }
          setFrames(framesArr);
        })
        .catch((error) => console.log(error));
    }, 100);
  }, [secondsToPixelsRatio, video, videoDuration]);

  return (
    <div className={classes.container}>
      <div
        className={classes.verticalLine}
        style={{
          zIndex: 2,
          left: cursorVerticalLinePosition - 6,
          transition: isCursorAnimationDisabled ? 'none' : 'left 0.6s ease-out',
          height: verticalLineHeight,
          display:
            cursorVerticalLinePosition < 0 ||
            (setRef.current &&
              cursorVerticalLinePosition > setRef.current.clientWidth)
              ? 'none'
              : 'block',
        }}
      />
      <div
        className={classes.SceneLine}
        ref={setRef}
        onClick={(event) => {
          moveCursorOnClick(event);
        }}
        style={{ overflowX: zoomValue === 0 ? 'hidden' : 'auto' }}
      >
        <div className={classes.timeline}>{timeIntervals}</div>
        <div className={classes.frames}>{frames}</div>
        <div className={classes.footer} />

        <div
          className={classes.cursor}
          style={{
            left: currentTime * secondsToPixelsRatio - 6,
            transition: isCursorAnimationDisabled
              ? 'none'
              : 'left 0.6s ease-out',
            pointerEvents: isCursorAnimationDisabled ? 'none' : 'initial',
          }}
          ref={cursorRef}
          onMouseDown={() => {
            if (videoRef.current) {
              if (isPresentationMode) {
                return;
              }
              videoRef.current.pause();
            }
          }}
        />
      </div>
    </div>
  );
}
