import { useEffect, useState } from 'react';

export default function useDraggable(selectableRef, onFinishSelection) {
  const [xPosition, setXPosition] = useState(0);

  const move = (event) => {
    if (selectableRef.current) {
      const coords = selectableRef.current.getBoundingClientRect();
      setXPosition(
        event.clientX - coords.left + selectableRef.current.scrollLeft
      );
    }
  };

  const removeMoveListener = () => {
    if (document.body.classList.contains('movelistener')) {
      return;
    }
    console.log('REMOVE LISTENER');
    document.body.classList.remove('moveListener');
    document.body.removeEventListener('mousemove', move);
    document.body.removeEventListener('click', removeMoveListener);
    setXPosition(0);
    onFinishSelection();

    // eslint-disable-next-line no-use-before-define
    selectableRef.current.addEventListener('click', onMouseClick);
  };

  function onMouseClick(event) {
    if (
      !event.target.hasAttribute('chart') ||
      document.body.classList.contains('moveListener')
    ) {
      return;
    }

    console.log('ADD LISTENER');

    selectableRef.current.removeEventListener('click', onMouseClick);
    document.body.addEventListener('mousemove', move);

    setTimeout(() => {
      document.body.addEventListener('click', removeMoveListener);
    }, 10);
  }

  useEffect(() => {
    if (selectableRef.current) {
      selectableRef.current.addEventListener('click', onMouseClick);
    }
    return () => {
      if (selectableRef.current) {
        document.body.removeEventListener('mousemove', move);
        selectableRef.current.removeEventListener('click', onMouseClick);
      }
    };
  }, []);

  return {
    xPosition,
  };
}
