import React from 'react';

import { NavLink } from 'react-router-dom';
import classnames from 'classnames';

import classes from './styles.module.scss';

export default function FormField({
  type,
  label,
  placeholder,
  disabled,
  readOnly,
  value,
  isInvalid,
  errorMessage,
  isValid,
  successMessage,
  forgotPassword,
  onChange,
  onBlur,
  setRef,
}) {
  return (
    /* eslint-disable-next-line jsx-a11y/label-has-associated-control */
    <label
      className={classnames(
        classes.FormField,
        isInvalid && classes.isInvalid,
        isValid && classes.isValid,
        forgotPassword && classes.forgotPassword
      )}
    >
      <span className={classes.label}>{label}</span>
      <input
        disabled={disabled}
        readOnly={readOnly}
        type={type || 'text'}
        placeholder={placeholder}
        value={value}
        onChange={onChange}
        onBlur={onBlur}
        ref={setRef}
      />
      <span className={classes.errorMessage}>{errorMessage}</span>
      <span className={classes.successMessage}>{successMessage}</span>
      <NavLink to="/forgot-password" className={classes.forgotPasswordLink}>
        Forgot password?
      </NavLink>
    </label>
  );
}
