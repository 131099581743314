import React from 'react';

import FileManager from '../../components/FileManager';

// import classes from './styles.module.scss';

export default function HomePage({ searchResults, setSearchResults }) {
  return (
    <>
      <FileManager
        searchResults={searchResults}
        setSearchResults={setSearchResults}
      />
    </>
  );
}
