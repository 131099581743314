/* eslint-disable import/no-named-as-default-member */
/* eslint-disable import/no-named-as-default */
/* eslint-disable jsx-a11y/media-has-caption */
import React, { useRef, useEffect, useState, useCallback } from 'react';

import { connect } from 'react-redux';
import { useLocation } from 'react-router-dom';
import axios from 'axios';

import detectBrowser from '../../../helpers/detectBrowser';
import { REACT_APP_API_URL } from '../../../constants/main';
import { VideoContext } from '../../../context/index';
import Filters from './Filters';
import PercentageGraphs from './PercentageGraphs';
import RightMenu from './RightMenu';
import LeftMenu from './LeftMenu';
import SceneLine from './SceneLine';
import MainCharts from './MainCharts';
import Comments from './Comments';
import ErrorMessageModal from '../../../modals/ErrorMessageModal';
import VideoPlayerModal from '../../../modals/VideoPlayerModal';

import classes from './styles.module.scss';

function EditFile({
  video,
  accessToken,
  presentationHighlights,
  presentationThreads,
  highlights,
  getSharedEntityLink,
}) {
  const [fullScreenButtonPosition, setFullScreenButtonPosition] = useState({
    top: 0,
    left: 0,
  });
  const [fullScreenButtonSize, setFullScreenButtonSize] = useState({
    width: 0,
    height: 0,
  });
  const [zoomValue, setZoomValue] = useState(0);
  const [isZoomBarDisabled, setIsZoomBarDisabled] = useState(false);
  const [isCommentsSideBarVisible, setIsCommentsSideBarVisible] = useState(
    false
  );
  const [currentTime, setCurrentTime] = useState(0);
  const [threads, setThreads] = useState([]);
  const [currentTimeStamp, setCurrentTimeStamp] = useState(null);
  const [visibleThreads, setVisibleThreads] = useState([]);
  const [threadsPage, setThreadsPage] = useState(0);
  const [secondsToPixelsRatio, setSecondsToPixelsRatio] = useState(2);
  const [videoDuration, setVideoDuration] = useState(null);
  const [highlightColor, setHighlightColor] = useState('purple');
  const [isPresentationMode, setIsPresentationMode] = useState(false);
  const [presentationAvailability, setPresentationAvailability] = useState(
    true
  );
  const [isPresentationActive, setIsPresentationActive] = useState(false);
  const [isErrorMessageModalVisible, setIsErrorMessageModalVisible] = useState(
    false
  );
  const [videoUrl, setVideoUrl] = useState('');
  const [isVideoPlayerModalVisible, setIsVideoPlayerModalVisible] = useState(
    false
  );
  const [isSharedVideoMode, setIsSharedVideoMode] = useState(false);

  const videoRef = useRef();
  const sceneLineRef = useRef();

  const presentationConnectionRef = useRef(null);
  const presentationRequestRef = useRef(null);

  const query = new URLSearchParams(useLocation().search);

  useEffect(() => {
    if (video && videoRef.current) {
      videoRef.current.load();
    }
  }, [videoUrl]);

  useEffect(() => {
    if (
      JSON.parse(localStorage?.getItem('authData'))?.userId !==
      video.ownerUserId
    ) {
      setIsSharedVideoMode(true);
    }
  }, []);

  const applyFilter = (filterName) => {
    let { path } = video;
    if (filterName === 'gauss') {
      path = video.gaussFilteredPath;
    } else if (filterName === 'gaussGray') {
      path = video.gaussGreyFilteredPath;
    }
    axios
      .get(`${REACT_APP_API_URL}/files/getUrl?key=${path}`, {
        headers: {
          authorization:
            accessToken ||
            `${JSON.parse(localStorage.getItem('authData')).accessToken}`,
        },
      })
      .then((response) => {
        setVideoUrl(response.data.url);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const positionFullScreenButton = () => {
    const sBrowser = detectBrowser();

    if (sBrowser === 'Google Chrome or Chromium') {
      setFullScreenButtonSize({ width: 50, height: 45 });
      setFullScreenButtonPosition({
        top: 192,
        left: 255,
      });
    } else if (sBrowser === 'Mozilla Firefox') {
      setFullScreenButtonSize({ width: 35, height: 40 });
      setFullScreenButtonPosition({
        top: 222,
        left: 310,
      });
    }
  };

  useEffect(() => {
    if (query.get('presentationMode')) {
      setIsPresentationMode(true);
    }
    if (query.get('sharedByLinkMode')) {
      setIsSharedVideoMode(true);
    }
    if (videoRef.current) {
      positionFullScreenButton();
    }
  }, []);

  useEffect(() => {
    if (threads.length) {
      setVisibleThreads(threads.slice(0, threadsPage * 5 + 5));
    }
  }, [threadsPage, threads]);

  const changeZoomValue = (value) => {
    // eslint-disable-next-line radix
    const numValue = parseInt(value);
    setZoomValue((prevState) => {
      if (prevState + numValue < 0 || prevState + numValue > 100) {
        if (numValue > 0) {
          return 100;
        }
        return 0;
      }
      return prevState + numValue;
    });
  };

  const showCommentsSideBar = (time) => {
    if (!Number.isNaN(time)) {
      setCurrentTimeStamp(time);
    }
    setIsCommentsSideBarVisible(true);
  };

  const hideCommentsSideBar = () => {
    setIsCommentsSideBarVisible(false);
  };

  const showErrorMessageModal = () => {
    setIsErrorMessageModalVisible(true);
  };

  const hideErrorMessageModal = () => {
    setIsErrorMessageModalVisible(false);
  };

  const showVideoPlayerModal = () => {
    setIsVideoPlayerModalVisible(true);
  };

  const hideVideoPlayerModal = () => {
    setIsVideoPlayerModalVisible(false);
  };

  useEffect(() => {
    if (!presentationAvailability) {
      showErrorMessageModal();
      setPresentationAvailability(true);
    }
  }, [presentationAvailability]);

  useEffect(() => {
    if (isCommentsSideBarVisible) {
      document.body.style.overflow = 'hidden';
    } else {
      document.body.style.overflow = 'initial';
    }
  }, [isCommentsSideBarVisible]);

  const sendMessageToPresentationPage = (message) => {
    try {
      presentationConnectionRef.current.send(JSON.stringify(message));
    } catch (error) {
      // console.log(error);
    }
  };

  const startPresentationVideoPlay = () => {
    sendMessageToPresentationPage({ message: 'startPlaying' });
  };

  const pausePresentationVideo = () => {
    sendMessageToPresentationPage({ message: 'pause' });
  };

  const seekPresentationVideo = () => {
    if (!videoRef.current) {
      return;
    }
    sendMessageToPresentationPage({
      message: 'seek',
      payload: videoRef.current.currentTime,
    });
  };

  const requestPresentationHighlights = () => {
    sendMessageToPresentationPage({
      message: 'fetchPresentationHighlights',
    });
  };

  const requestPresentationThreads = () => {
    sendMessageToPresentationPage({
      message: 'fetchPresentationThreads',
    });
  };

  useEffect(() => {
    if (video) {
      axios
        .get(`${REACT_APP_API_URL}/files/getUrl?key=${video.path}`, {
          headers: {
            authorization:
              accessToken ||
              `${JSON.parse(localStorage.getItem('authData')).accessToken}`,
          },
        })
        .then((response) => {
          setVideoUrl(response.data.url);
        })
        .catch((error) => {
          console.log(error);
        });

      /* if (video.framesPath) {
        axios
          .get(
            `${REACT_APP_API_URL}/files/getUrl?key=${video.framesPath}/1.jpg`,
            {
              headers: {
                authorization:
                  accessToken ||
                  `${JSON.parse(localStorage.getItem('authData')).accessToken}`,
              },
            }
          )
          .then((response) => {
            console.log('FRAMES PATH', video.framesPath, response.data.url);
          })
          .catch((error) => {
            console.log(error);
          });
      } */
    }
  }, [video]);

  const fetchThreads = () => {
    axios
      .get(`${REACT_APP_API_URL}/threads`, {
        params: {
          fileId: video.id,
        },
        headers: {
          authorization:
            accessToken ||
            `${JSON.parse(localStorage.getItem('authData')).accessToken}`,
        },
      })
      .then((response) => {
        setThreads(response.data);
        requestPresentationThreads();
      })
      .catch((error) => {
        console.log(error);
      });
  };

  useEffect(() => {
    requestPresentationHighlights();
  }, [highlights]);

  useEffect(() => {
    if (videoRef.current) {
      videoRef.current.addEventListener('play', startPresentationVideoPlay);
      videoRef.current.addEventListener('pause', pausePresentationVideo);
      videoRef.current.addEventListener('seeked', seekPresentationVideo);
    }

    return () => {
      if (videoRef.current) {
        videoRef.current.removeEventListener(
          'play',
          startPresentationVideoPlay
        );
        videoRef.current.removeEventListener('pause', pausePresentationVideo);
        videoRef.current.removeEventListener('seeked', seekPresentationVideo);
      }
    };
  }, []);

  // eslint-disable-next-line consistent-return
  const togglePresentation = useCallback(() => {
    // console.log('PRESENTATION', isPresentationActive);
    videoRef.current.pause();
    if (isPresentationActive) {
      return presentationConnectionRef.current.terminate();
    }

    try {
      presentationRequestRef.current = new PresentationRequest([
        `/presentation?token=${
          JSON.parse(localStorage.getItem('authData')).accessToken
        }&videoId=${video.id}&presentationMode=true&currentTime=${
          videoRef.current.currentTime
        }`,
      ]);

      // Make this presentation the default one when using the "Cast" browser menu.
      navigator.presentation.defaultRequest = presentationRequestRef.current;

      presentationRequestRef.current
        .start()
        .then((connection) => {
          console.log(`> Connected to ${connection.url}, id: ${connection.id}`);
        })
        .catch((err) => {
          console.log(`> ${err.name}: ${err.message}`);
        });

      presentationRequestRef.current.addEventListener(
        'connectionavailable',
        (event) => {
          setIsPresentationActive(true);
          presentationConnectionRef.current = event.connection;
          presentationConnectionRef.current.addEventListener('close', () => {
            setIsPresentationActive(false);
            console.log('> Connection closed.');
          });
          presentationConnectionRef.current.addEventListener(
            'terminate',
            () => {
              setIsPresentationActive(false);
              console.log('> Connection terminated.');
            }
          );
        }
      );
    } catch (err) {
      setPresentationAvailability(false);
    }
  }, [isPresentationActive]);

  return (
    <VideoContext.Provider
      value={[video, videoRef, isPresentationMode, setIsPresentationMode]}
    >
      <main className={classes.EditFile}>
        <div className={classes.zoomBar}>
          <i
            className={classes.decreaseZoom}
            onClick={() => {
              if (isPresentationMode) {
                return;
              }
              changeZoomValue(-10);
            }}
          >
            Decrease zoom
          </i>
          <input
            type="range"
            value={zoomValue}
            onChange={(event) => setZoomValue(+event.target.value)}
            disabled={isZoomBarDisabled || isPresentationMode}
          />
          <i
            className={classes.increaseZoom}
            onClick={() => {
              if (isPresentationMode) {
                return;
              }
              changeZoomValue(10);
            }}
          >
            Increase zoom
          </i>
          <span>{zoomValue}%</span>
        </div>
        <div className={classes.firstRow}>
          <div className={classes.filters}>
            <Filters
              isPresentationMode={isPresentationMode}
              isSharedVideoMode={isSharedVideoMode}
              onApplyFilter={applyFilter}
              video={video}
            />
          </div>
          <div className={classes.videoPlayer}>
            <div
              className={classes.fullScreenButton}
              onClick={() => {
                if (isPresentationMode) {
                  return;
                }
                // videoRef.current.pause();
                showVideoPlayerModal();
              }}
              style={{
                width: fullScreenButtonSize.width,
                height: fullScreenButtonSize.height,
                top: fullScreenButtonPosition.top,
                left: fullScreenButtonPosition.left,
                cursor:
                  detectBrowser() === 'Google Chrome or Chromium'
                    ? 'pointer'
                    : 'auto',
              }}
            />
            <video
              ref={videoRef}
              controls={!isPresentationMode}
              id="video"
              muted={isPresentationActive}
            >
              <source src={videoUrl} />
            </video>
          </div>
          <div className={classes.leftMenu}>
            {!isPresentationMode && !isSharedVideoMode && (
              <LeftMenu
                highlightColor={highlightColor}
                setHighlightColor={setHighlightColor}
                video={video}
              />
            )}
          </div>
          <div className={classes.percentageGraphs}>
            <PercentageGraphs video={video} />
          </div>
          <div className={classes.rightMenu}>
            {!isPresentationMode && (
              <RightMenu
                showCommentsSideBar={showCommentsSideBar}
                video={video}
                togglePresentation={togglePresentation}
                getSharedEntityLink={getSharedEntityLink}
                isSharedVideoMode={isSharedVideoMode}
              />
            )}
          </div>
        </div>
        <div className={classes.secondRow}>
          <SceneLine
            threads={threads}
            presentationThreads={presentationThreads}
            zoomValue={zoomValue}
            setZoomValue={setZoomValue}
            setIsZoomBarDisabled={setIsZoomBarDisabled}
            currentTime={currentTime}
            setCurrentTime={setCurrentTime}
            showCommentsSideBar={showCommentsSideBar}
            secondsToPixelsRatio={secondsToPixelsRatio}
            setSecondsToPixelsRatio={setSecondsToPixelsRatio}
            videoDuration={videoDuration}
            setVideoDuration={setVideoDuration}
            setRef={sceneLineRef}
            videoUrl={videoUrl}
            video={video}
          />
          <MainCharts
            secondsToPixelsRatio={secondsToPixelsRatio}
            videoDuration={videoDuration}
            highlightColor={highlightColor}
            setHighlightColor={setHighlightColor}
            video={video}
            sceneLineRef={sceneLineRef}
            presentationHighlights={presentationHighlights}
            isSharedVideoMode={isSharedVideoMode}
          />
        </div>
        <Comments
          currentTimeStamp={currentTimeStamp}
          fetchThreads={fetchThreads}
          threads={threads}
          visibleThreads={visibleThreads}
          show={isCommentsSideBarVisible}
          onHide={hideCommentsSideBar}
          currentTime={currentTime}
          video={video}
          threadsPage={threadsPage}
          setThreadsPage={setThreadsPage}
        />
      </main>
      <ErrorMessageModal
        show={isErrorMessageModalVisible}
        onHide={hideErrorMessageModal}
        message="Unfortunately, your browser doesn't support the presentation API. For presentation, you can use Chrome, Edge, or Opera."
      />
      <VideoPlayerModal
        show={isVideoPlayerModalVisible}
        onHide={hideVideoPlayerModal}
        video={video}
        videoUrl={videoUrl}
        smallVideoRef={videoRef}
      />
    </VideoContext.Provider>
  );
}

export default connect((state) => ({
  highlights: state.highlights.items,
}))(EditFile);
