import React, { useState, useEffect } from 'react';

import classes from './styles.module.scss';

export default function Charts({ video, videoRef }) {
  const [climax, setClimax] = useState(0);
  const [soundIntencity, setSoundIntencity] = useState(0);
  const [cluttering, setCluttering] = useState(0);
  const [flickering, setFlickering] = useState(0);

  const updateCharts = () => {
    if (!video.climax) {
      return;
    }
    const curretnTimePercentage =
      videoRef.current.currentTime / videoRef.current.duration;

    const currentChartElement = Math.ceil(
      video.climax.length * curretnTimePercentage
    );
    setClimax(video.climax[currentChartElement]);
    setSoundIntencity(video.soundIntencity[currentChartElement]);
    setCluttering(video.cluttering[currentChartElement]);
    setFlickering(video.flickering[currentChartElement]);
  };

  useEffect(() => {
    if (videoRef.current) {
      videoRef.current.addEventListener('timeupdate', updateCharts);
    }
    return () => {
      if (videoRef.current) {
        videoRef.current.removeEventListener('timeupdate', updateCharts);
      }
    };
  }, []);

  return (
    <div className={classes.Charts}>
      <div className={classes.chart}>
        <h1>Climax</h1>
        <div style={{ width: `${climax}%` }} />
      </div>
      <div className={classes.chart}>
        <h1>Sound intencity</h1>
        <div style={{ width: `${soundIntencity}%` }} />
      </div>
      <div className={classes.chart}>
        <h1>Cluttering</h1>
        <div style={{ width: `${cluttering}%` }} />
      </div>
      <div className={classes.chart}>
        <h1>Flickering</h1>
        <div style={{ width: `${flickering}%` }} />
      </div>
      <div className={classes.axis}>
        <span>0</span>
        <span>10</span>
        <span>20</span>
        <span>30</span>
        <span>40</span>
        <span>50</span>
        <span>60</span>
        <span>70</span>
        <span>89</span>
        <span>90</span>
        <span>100</span>
      </div>
    </div>
  );
}
