/* eslint-disable jsx-a11y/media-has-caption */
import React from 'react';

import moment from 'moment';
import classnames from 'classnames';

import DropDownMenu from '../DropDownMenu';
import VideoProcessingSpinner from './VideoProcessingSpinner';
import errorIcon from '../../../assets/images/errorIcon.svg';

import {
  REACT_APP_API_URL,
  VIDEO_PROCESSING_STATUSES,
} from '../../../constants/main';
import classes from './styles.module.scss';

function getVideoStatus(video) {
  switch (video.processingStatus) {
    case VIDEO_PROCESSING_STATUSES.NOT_STARTTED:
    case VIDEO_PROCESSING_STATUSES.IN_PROGRESS:
      return 'processing';
    case VIDEO_PROCESSING_STATUSES.ERROR:
      return 'error';
    case VIDEO_PROCESSING_STATUSES.SUCCESS:
      return 'success';
    default:
      return 'error';
  }
}

export default function File({
  file,
  currentViewMode,
  showFileDetails,
  showShareEntityModal,
  showRenameFileModal,
  deleteFile,
  setFolderContentElement,
  showMoveFileModal,
  shared,
  onClick,
  openFile,
  getSharedEntityLink,
}) {
  return (
    <div
      onClick={onClick}
      className={classnames(
        classes.file,
        currentViewMode === 'grid' && classes.grid,
        (currentViewMode === 'list' || currentViewMode === null) && classes.list
      )}
    >
      <div className={classes.videoContainer}>
        {currentViewMode === 'grid' && getVideoStatus(file) === 'processing' && (
          <div className={classes.spinner}>
            <VideoProcessingSpinner />
          </div>
        )}
        {currentViewMode === 'grid' && getVideoStatus(file) === 'error' && (
          <div className={classes.errorContainer}>
            <div className={classes.error}>
              <img src={errorIcon} alt="Error icon" />
              <span>Error load...</span>
            </div>
          </div>
        )}
        <video className={classes.preview}>
          <source src={`${REACT_APP_API_URL}/files/getFile/${file.path}`} />
        </video>
      </div>
      <footer>
        <i>video</i>
        <h1 className={classes.title}>{file.name}</h1>
        {(currentViewMode === 'list' || currentViewMode === null) && (
          <>
            <span className={classes.createdAt}>
              {moment(file.createdAt).format('DD.MM.YYYY')}
            </span>
            <span className={classes.updatedAt}>
              {moment(file.updatedAt).format('DD.MM.YYYY')}
            </span>
          </>
        )}
        <div className={classes.dropDownContainer}>
          <DropDownMenu
            file={file}
            onFileDetails={() => showFileDetails(file)}
            onShare={() => showShareEntityModal(file)}
            onRename={() => showRenameFileModal(file)}
            onRemove={() =>
              deleteFile(file).then(() => {
                setFolderContentElement({
                  element: null,
                  status: 'moved',
                });
              })
            }
            onMove={() => showMoveFileModal(file)}
            shared={shared}
            onOpen={() => openFile(file)}
            onGetShareableLink={() => getSharedEntityLink(file)}
          />
        </div>
      </footer>
    </div>
  );
}
