import React from 'react';

import { useHistory } from 'react-router-dom';
import { connect } from 'react-redux';
import * as Yup from 'yup';
import { Formik } from 'formik';

import { actions } from '../../resources/users';
import EmptyHeader from '../../components/EmptyHeader';
import FormField from '../../components/Form/FormField';
import CheckBox from '../../components/Form/CheckBox';
import StyledButton from '../../components/Form/StyledButton';

import classes from './styles.module.scss';

const ValidationSchema = Yup.object().shape({
  email: Yup.string()
    .email('Please enter a valid email')
    .required('Email is a required field'),
  password: Yup.string()
    .required('Password is a required field')
    .min(8, 'Password must be at least 8 characters'),
  passwordConfirmation: Yup.string()
    .required('Password confirmation is a required field')
    .min(8, 'Password must be at least 8 characters')
    .oneOf([Yup.ref('password'), null], 'Passwords must match'),
  toa: Yup.bool().oneOf([true], 'You need to accept terms & conditions'),
});

function RegistrationPage({ createUserAction }) {
  const history = useHistory();
  return (
    <div className={classes.RegistrationPage}>
      <EmptyHeader />
      <div className={classes.formContainer}>
        <h2>Sign Up</h2>
        <Formik
          initialValues={{
            email: '',
            password: '',
            passwordConfirmation: '',
            toa: false,
          }}
          validationSchema={ValidationSchema}
          onSubmit={(values, { setFieldError }) => {
            createUserAction({
              email: values.email,
              password: values.password,
            })
              .then(() => {
                history.push('/login');
              })
              .catch((error) => {
                if (
                  error.body &&
                  error.body.message === 'email is already in use'
                ) {
                  setFieldError('email', 'email is already in use');
                }
              });
          }}
        >
          {({
            values,
            errors,
            touched,
            handleChange,
            handleBlur,
            handleSubmit,
            // setFieldValue,
            // isSubmitting,
          }) => (
            <form className={classes.registrationForm}>
              <FormField
                label="Email address"
                onChange={handleChange('email')}
                onBlur={handleBlur('email')}
                value={values.email}
                isInvalid={touched.email && errors.email}
                errorMessage={errors.email}
              />
              <FormField
                label="Password"
                type="password"
                onChange={handleChange('password')}
                onBlur={handleBlur('password')}
                value={values.password}
                isInvalid={touched.password && errors.password}
                errorMessage={errors.password}
              />
              <FormField
                label="Confirm Password"
                type="password"
                onChange={handleChange('passwordConfirmation')}
                onBlur={handleBlur('passwordConfirmation')}
                value={values.passwordConfiramtion}
                isInvalid={
                  touched.passwordConfirmation && errors.passwordConfirmation
                }
                errorMessage={errors.passwordConfirmation}
              />
              <CheckBox
                label="Accept Terms & Conditions"
                checked={values.toa}
                onChange={handleChange('toa')}
                onBlur={handleBlur('toa')}
                isInvalid={touched.toa && errors.toa}
                errorMessage={errors.toa}
              />
              <StyledButton
                style={{ alignSelf: 'center' }}
                label="Sign Up"
                onClick={handleSubmit}
              />
            </form>
          )}
        </Formik>
      </div>
    </div>
  );
}

export default connect(null, {
  createUserAction: actions.createUser,
})(RegistrationPage);
