/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
import React, {
  useState,
  useEffect,
  useRef,
  useContext,
  Fragment,
} from 'react';

import { connect } from 'react-redux';
import classnames from 'classnames';

import CreateHighlight from '../CreateHighlight';
import Highlight from '../Highlight';

import { VideoContext, HighlightContext } from '../../../../../context/index';
import { actions } from '../../../../../resources/highlights';
import { HIGHLIGHT_COLORS } from '../../../../../constants/main';
import isOverlapping from '../../../../../helpers/isOverlapping';
import useClickAndHighlight from '../../../../../helpers/useClickAndHighlight';
import classes from './styles.module.scss';

function PixelatedBarChart({
  title,
  secondsToPixelsRatio,
  videoDuration,
  highlightColor,
  setHighlightColor,
  video,
  fetchHighlights,
  highlights,
  areHighlightsFetching,
  setRef,
  presentationHighlights,
  isSharedVideoMode,
  chartData,
}) {
  const [actualData, setActualData] = useState([]);
  const [lines, setLines] = useState([]);
  const [isSelectable, setIsSelectable] = useState(false);
  const [selectionStartPosition, setSelectionStartPosition] = useState(0);
  const [selectionWidth, setSelectionWidth] = useState(0);
  const [selectionLeft, setSelectionLeft] = useState(0);
  const [chartDataElementsPerSecond, setChartDataElementsPerSecond] = useState(
    20
  );

  const highLightRef = useRef();

  const [, , isPresentationMode] = useContext(VideoContext);
  const [, , , , , , isAddHighlightModeActive] = useContext(HighlightContext);

  const deleteSelection = () => {
    setSelectionWidth(0);
    document.body.classList.remove('moveListener');
  };

  const finishSelection = () => {
    setIsSelectable(false);
    document.body.classList.remove('moveListener');
    if (
      isOverlapping(
        highLightRef,
        document.querySelectorAll('[ishighlight="true"]')
      )
    ) {
      deleteSelection();
    }
  };

  const { xPosition } = useClickAndHighlight(setRef, finishSelection);

  const getClickXPosition = (pageX) => {
    const coords = setRef.current.getBoundingClientRect();
    return pageX - coords.left + setRef.current.scrollLeft;
  };

  useEffect(() => {
    fetchHighlights(null, { query: { fileId: video.id } });
  }, []);

  useEffect(() => {
    if (!isSelectable) {
      return;
    }

    if (selectionStartPosition > videoDuration * secondsToPixelsRatio + 8) {
      document.body.classList.remove('moveListener');
      document.body.click();
      return;
    }

    if (selectionStartPosition < xPosition) {
      setSelectionLeft(selectionStartPosition);
      let width =
        selectionStartPosition < xPosition
          ? xPosition - selectionStartPosition
          : 0;
      if (xPosition > videoDuration * secondsToPixelsRatio + 8) {
        width =
          videoDuration * secondsToPixelsRatio + 8 - selectionStartPosition;
      }
      setSelectionWidth(width);
    } else if (selectionStartPosition > xPosition && xPosition !== 0) {
      setSelectionLeft(xPosition);
      setSelectionWidth(selectionStartPosition - xPosition);
    }
  }, [xPosition, selectionStartPosition, isSelectable]);

  const startSelection = (pageX) => {
    if (document.body.classList.contains('moveListener')) {
      return;
    }
    document.body.classList.add('moveListener');
    setIsSelectable(true);
    setSelectionWidth(0);
    setSelectionStartPosition(getClickXPosition(pageX));
  };

  useEffect(() => {
    if (videoDuration && chartData) {
      setChartDataElementsPerSecond(chartData.length / videoDuration);
      setActualData(chartData);
    }
  }, [videoDuration, chartData]);

  useEffect(() => {
    if (chartData && actualData) {
      const linesArr = [];
      for (let i = 0; i < actualData.length; i += 1) {
        linesArr.push(
          <Fragment key={i}>
            <line
              chart="true"
              key={i}
              x1={i / (chartDataElementsPerSecond / secondsToPixelsRatio)}
              y1={30}
              x2={i / (chartDataElementsPerSecond / secondsToPixelsRatio)}
              y2={30 - 30 * (actualData[i] / 100)}
              style={{ stroke: 'rgb(83, 159, 111)', strokeWidth: 1 }}
            />
            {30 - 30 * (actualData[i] / 100) <= 15 && (
              <line
                x1={i / (chartDataElementsPerSecond / secondsToPixelsRatio)}
                y1={15}
                x2={i / (chartDataElementsPerSecond / secondsToPixelsRatio)}
                y2={13}
                style={{ stroke: 'rgb(65, 136, 91)', strokeWidth: 1 }}
              />
            )}
            {30 - 30 * (actualData[i] / 100) <= 13 && (
              <line
                x1={i / (chartDataElementsPerSecond / secondsToPixelsRatio)}
                y1={13}
                x2={i / (chartDataElementsPerSecond / secondsToPixelsRatio)}
                y2={11}
                style={{ stroke: 'rgb(105, 188, 135)', strokeWidth: 1 }}
              />
            )}
            {/* {30 - 30 * (randomData[i] / 100) <= 12 && (
              <line
                x1={i}
                y1={12}
                x2={i}
                y2={11}
                style={{ stroke: 'rgb(148, 183, 98)', strokeWidth: 1 }}
              />
            )}
            {30 - 30 * (randomData[i] / 100) <= 11 && (
              <line
                x1={i}
                y1={11}
                x2={i}
                y2={9}
                style={{ stroke: 'rgb(183, 200, 89)', strokeWidth: 1.25 }}
              />
            )} */}
            {30 - 30 * (actualData[i] / 100) <= 9 && (
              <line
                x1={i / (chartDataElementsPerSecond / secondsToPixelsRatio)}
                y1={9}
                x2={i / (chartDataElementsPerSecond / secondsToPixelsRatio)}
                y2={8}
                style={{ stroke: 'rgb(228, 217, 81)', strokeWidth: 1.25 }}
              />
            )}
            {30 - 30 * (actualData[i] / 100) <= 8 && (
              <line
                x1={i / (chartDataElementsPerSecond / secondsToPixelsRatio)}
                y1={8}
                x2={i / (chartDataElementsPerSecond / secondsToPixelsRatio)}
                y2={30 - 30 * (actualData[i] / 100)}
                style={{ stroke: 'rgb(246, 15, 27)', strokeWidth: 1.25 }}
              />
            )}
          </Fragment>
        );
      }
      setLines(linesArr);
    }
  }, [actualData, secondsToPixelsRatio, chartData]);

  return (
    <div
      id={title.split(' ').join('')}
      className={classes.PixelatedBarChart}
      ref={setRef}
      // style={{
      //   paddingBottom:
      //     setRef.current &&
      //     setRef.current.scrollWidth > setRef.current.clientWidth
      //       ? 68
      //       : 55,
      // }}
    >
      <header className={classes.pixelatedBarChartHeader}>
        <h1>{title}</h1>
      </header>
      <main
        className={classes.pixelatedBarChartMain}
        style={{ minWidth: videoDuration * secondsToPixelsRatio }}
      >
        <div className={classes.overlay} />
        <div className={classes.chart}>
          <svg width="100%" height="30" chart="true">
            {lines}
          </svg>
        </div>
      </main>
      <div
        className={classnames(
          classes.selection,
          selectionWidth > 0 && !isSelectable && classes.selected
        )}
        style={{
          left: selectionLeft,
          width: selectionWidth,
          backgroundColor: HIGHLIGHT_COLORS[highlightColor.toUpperCase()][1],
          borderBottom: `1px solid ${
            HIGHLIGHT_COLORS[highlightColor.toUpperCase()][0]
          }`,
        }}
        ref={highLightRef}
      >
        <CreateHighlight
          selectionWidth={selectionWidth}
          selectionRef={highLightRef}
          show={selectionWidth > 0 && !isSelectable}
          currentColor={highlightColor}
          setCurrentColor={setHighlightColor}
          deleteSelection={deleteSelection}
          secondsToPixelsRatio={secondsToPixelsRatio}
          selectionLeft={selectionLeft}
          video={video}
          chartTitle={title}
        />
      </div>
      <div
        className={classnames(
          classes.highlights,
          isAddHighlightModeActive && classes.selectionActive
        )}
        chart="true"
        onClick={(event) => {
          if (
            isPresentationMode ||
            isSharedVideoMode ||
            !isAddHighlightModeActive
          ) {
            return;
          }
          if (!isSelectable) {
            startSelection(event.pageX);
          }
        }}
      >
        {!presentationHighlights &&
          highlights &&
          !areHighlightsFetching &&
          highlights.map((highlight) => {
            if (highlight.chart !== title) {
              return null;
            }
            return (
              <div
                key={highlight.id}
                className={classes.highlight}
                style={{ left: highlight.startTime * secondsToPixelsRatio }}
              >
                <Highlight
                  highlight={highlight}
                  secondsToPixelsRatio={secondsToPixelsRatio}
                />
              </div>
            );
          })}
        {presentationHighlights &&
          presentationHighlights.map((highlight) => {
            if (highlight.chart !== title) {
              return null;
            }
            return (
              <div
                key={highlight.id}
                className={classes.highlight}
                style={{ left: highlight.startTime * secondsToPixelsRatio }}
              >
                <Highlight
                  highlight={highlight}
                  secondsToPixelsRatio={secondsToPixelsRatio}
                />
              </div>
            );
          })}
      </div>
    </div>
  );
}

export default connect(
  (state) => ({
    highlights: state.highlights.items,
    areHighlightsFetching: state.highlights.isFetching,
  }),
  {
    fetchHighlights: actions.fetchHighlights,
  }
)(PixelatedBarChart);
