import { createResource, defaultHeaders } from 'redux-rest-resource';

import { REACT_APP_API_URL } from '../constants/main';
// import { paginatedFetchReducer } from '../helpers/resourceReducers';
import { getAuthData } from '../helpers/authStorage';

const setAuthHeader = (accessToken) => {
  Object.assign(defaultHeaders, {
    Authorization: accessToken,
  });
};

/* const withSaveTokens = (res) => {
  setAuthHeader(res.body.accessToken);
  saveAuthData(res.body);

  return {
    res,
    body: res.body,
  };
};
 */
export const { types, actions, rootReducer } = createResource({
  name: 'folder',
  url: `${REACT_APP_API_URL}/folders/:id`,

  actions: {
    fetch: {
      url: `${REACT_APP_API_URL}/folders`,
      // reduce: paginatedFetchReducer,
    },
  },
});

setAuthHeader(getAuthData().accessToken);
