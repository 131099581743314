import React, { useRef, useEffect } from 'react';

import { useHistory, useLocation, NavLink } from 'react-router-dom';
import { connect } from 'react-redux';
import * as Yup from 'yup';
import { Formik } from 'formik';

import { actions } from '../../resources/users';
import EmptyHeader from '../../components/EmptyHeader';
import FormField from '../../components/Form/FormField';
import StyledButton from '../../components/Form/StyledButton';

import classes from './styles.module.scss';

const ValidationSchema = Yup.object().shape({
  email: Yup.string()
    .email('Please enter a valid email')
    .required('Email is a required field'),
  password: Yup.string()
    .required('Password is a required field')
    .min(8, 'Password must be at least 8 characters'),
});

function LoginPage({ loginUser }) {
  const history = useHistory();
  const query = new URLSearchParams(useLocation().search);

  const passwordInputRef = useRef();

  useEffect(() => {
    if (query.get('account') && passwordInputRef.current) {
      passwordInputRef.current.focus();
    }
  }, []);

  return (
    <div className={classes.LoginPage}>
      <EmptyHeader />
      <div className={classes.formContainer}>
        <h2>Sign In to Lookam</h2>
        <Formik
          initialValues={{
            email: query.get('account') || '',
            password: '',
          }}
          validationSchema={ValidationSchema}
          onSubmit={(values, { setFieldError }) => {
            console.log('SUBMIT');
            loginUser(values)
              .then(() => {
                history.push(
                  query.get('account') ? '/home?showShared=true' : '/home'
                );
              })
              .catch((error) => {
                setFieldError('email', error.body.message);
                setFieldError('password', ' ');
              });
          }}
        >
          {({
            values,
            errors,
            touched,
            handleChange,
            handleBlur,
            handleSubmit,
            // setFieldValue,
            // isSubmitting,
          }) => (
            // eslint-disable-next-line jsx-a11y/no-noninteractive-element-interactions
            <form
              className={classes.registrationForm}
              onKeyDown={(event) => {
                if (event.key === 'Enter') {
                  handleSubmit();
                }
              }}
            >
              <FormField
                label="Email address"
                onChange={handleChange('email')}
                onBlur={handleBlur('email')}
                value={values.email}
                isInvalid={touched.email && errors.email}
                errorMessage={errors.email}
              />
              <FormField
                label="Password"
                type="password"
                onChange={handleChange('password')}
                onBlur={handleBlur('password')}
                value={values.password}
                isInvalid={touched.password && errors.password}
                errorMessage={errors.password}
                forgotPassword
                setRef={passwordInputRef}
              />
              <StyledButton
                style={{ alignSelf: 'center' }}
                onClick={handleSubmit}
                label="Sign In"
              />
            </form>
          )}
        </Formik>
        <NavLink className={classes.signupLink} to="/registration">
          Sign Up
        </NavLink>
      </div>
    </div>
  );
}

export default connect(null, {
  loginUser: actions.loginUser,
})(LoginPage);
