import React, { useState, useEffect, useRef } from 'react';

import axios from 'axios';
import { connect } from 'react-redux';
import * as Yup from 'yup';
import { Formik } from 'formik';
import Modal from 'react-bootstrap/Modal';
import copy from 'copy-to-clipboard';

import { REACT_APP_API_URL } from '../../constants/main';
import { actions } from '../../resources/folders';
import FormField from '../../components/Form/FormField';
import StyledButton from '../../components/Form/StyledButton';
import '../../assets/bootstrap-modal.css';
import classes from './styles.module.scss';

const ValidationSchema = Yup.object().shape({
  email: Yup.string().email(),
});

function ShareEntityModal({ show, onHide, entity, showPopupMessage }) {
  const [emails, setEmails] = useState({ emails: [], send: false });
  const [sharedEntityLink, setSharedEntityLink] = useState('');

  const linkRef = useRef();

  const getSharedEntityLink = () => {
    if (!entity) {
      return;
    }
    axios({
      method: 'POST',
      url: `${REACT_APP_API_URL}/share/getLink`,
      data: {
        entityId: entity.id,
        entityType: entity.type ? 'file' : 'folder',
      },
      headers: {
        authorization: `${
          JSON.parse(localStorage.getItem('authData')).accessToken
        }`,
      },
    })
      .then((response) => {
        setSharedEntityLink(response.data.link);
      })
      .catch((error) => console.log(error));
  };

  useEffect(() => {
    if (show) {
      getSharedEntityLink();
    }
  }, [show]);

  const closeModal = () => {
    setSharedEntityLink('');
    setEmails({ emails: [], send: false });
    onHide();
  };
  const copyLinkToClipboard = async () => {
    if (!sharedEntityLink || !linkRef.current) {
      return;
    }

    /*  if (navigator.clipboard) {
      await navigator.clipboard.writeText(linkRef.current.value);
      showPopupMessage('The link was copied');
    } else {
      linkRef.current.select();
      document.execCommand('copy');
      showPopupMessage('The link was copied');
    } */

    copy(linkRef.current.value);
    showPopupMessage('The link was copied');
    closeModal();
  };

  const notifyUsers = () => {
    if (!emails.emails.length) {
      return;
    }
    axios({
      method: 'POST',
      url: `${REACT_APP_API_URL}/share/sendEmails`,
      data: {
        emails: emails.emails,
        entityId: entity.id,
        entityType: entity.type ? 'file' : 'folder',
        entityName: entity.name,
      },
      headers: {
        authorization: `${
          JSON.parse(localStorage.getItem('authData')).accessToken
        }`,
      },
    })
      .then(() => {
        // onHide();
      })
      .catch((error) => console.log(error));
    showPopupMessage(
      emails.length > 1 ? 'Emails have been sent' : 'An email has been sent'
    );
    closeModal();
  };

  useEffect(() => {
    if (emails.emails.length && emails.send) {
      notifyUsers();
    }
  }, [emails]);

  return (
    <Modal
      show={show}
      onHide={closeModal}
      size="md"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      dialogClassName={classes.ShareEntityModal}
    >
      <Modal.Header closeButton className={classes.header}>
        Share with people
      </Modal.Header>

      <Formik
        initialValues={{
          email: '',
          send: false,
        }}
        validationSchema={ValidationSchema}
        onSubmit={(values, { resetForm }) => {
          setEmails((prevState) => {
            if (values.email.trim() !== '') {
              let newEmails = [...prevState.emails, values.email];
              newEmails = Array.from(new Set(newEmails));
              return { emails: newEmails, send: values.send };
            }
            return {
              emails: prevState.emails,
              send: values.send && !!emails.emails.length,
            };
          });
          resetForm();
        }}
      >
        {({
          values,
          errors,
          touched,
          handleChange,
          handleBlur,
          handleSubmit,
          setFieldValue,
        }) => (
          <>
            <Modal.Body className={classes.body}>
              <div className={classes.shareLingContainer}>
                <FormField value={sharedEntityLink} setRef={linkRef} />
                <button type="button" onClick={copyLinkToClipboard}>
                  Copy link
                </button>
              </div>
              <form
                onSubmit={(event) => {
                  event.preventDefault();
                  setFieldValue('send', true);
                  handleSubmit();
                }}
                className={classes.emailForm}
              >
                <FormField
                  label="or add email"
                  onChange={handleChange('email')}
                  onBlur={handleBlur('email')}
                  value={values.email}
                  isInvalid={touched.email && errors.email}
                  errorMessage={errors.email}
                />
                <button
                  type="button"
                  className={classes.addEmail}
                  onClick={() => {
                    setFieldValue('send', false);
                    handleSubmit();
                  }}
                >
                  <span>+</span>
                </button>
                <StyledButton
                  style={{ top: touched.email && errors.email ? -7 : '' }}
                  label="Add"
                  onClick={() => {
                    setFieldValue('send', true);
                    handleSubmit();
                  }}
                />
              </form>
              <div className={classes.emails}>
                {!!emails.emails.length &&
                  emails.emails.map((email) => {
                    return (
                      <div key={email} className={classes.email}>
                        {email}
                        <i
                          onClick={() => {
                            setEmails((prevEmails) => {
                              const filteredEmails = prevEmails.filter(
                                (prevEmail) => prevEmail !== email
                              );
                              return filteredEmails;
                            });
                          }}
                        >
                          &#10006;
                        </i>
                      </div>
                    );
                  })}
              </div>
            </Modal.Body>
          </>
        )}
      </Formik>
    </Modal>
  );
}

export default connect(null, {
  updateFolder: actions.updateFolder,
})(ShareEntityModal);
