import React, { useEffect, useRef, useState } from 'react';

import classnames from 'classnames';

import classes from './styles.module.scss';

export default function PopupMessage({ show, message, onHide }) {
  const [messageWidth, setMessageWidth] = useState(0);
  const messageRef = useRef();
  useEffect(() => {
    if (show) {
      setTimeout(() => {
        onHide();
      }, 1500);
    }
  }, [show]);

  useEffect(() => {
    if (messageRef.current) {
      setMessageWidth(messageRef.current.offsetWidth);
    }
  }, [message, show]);

  return (
    <div className={classnames(classes.PopupMessage, show && classes.show)}>
      <div className={classes.popupOverlay} />
      <span
        ref={messageRef}
        style={{ left: `calc(50% - ${messageWidth / 2}px)` }}
      >
        {message}
      </span>
    </div>
  );
}
