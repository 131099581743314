import React, { useRef, useState, useEffect } from 'react';

import { connect } from 'react-redux';
import classnames from 'classnames';

import Thread from './Thread';

import { actions } from '../../../../resources/comments';
import formatTimeStamp from '../../../../helpers/formatTimeStamp';
import useOnClickOutside from '../../../../helpers/useOnClickOutside';
import classes from './styles.module.scss';

function Comments({
  show,
  onHide,
  currentTime,
  video,
  createComment,
  threads,
  visibleThreads,
  fetchThreads,
  currentTimeStamp,
  setThreadsPage,
}) {
  const [commentText, setCommentText] = useState('');

  const commentsRef = useRef();
  const textAreaRef = useRef();

  useOnClickOutside(commentsRef, onHide);

  const sendComment = (text, threadId) => {
    if (text.trim() === '') {
      return;
    }
    createComment({
      text,
      timeStamp: Math.floor(currentTime),
      fileId: video.id,
      threadId,
    })
      .then(() => {
        fetchThreads();
      })
      .catch((error) => console.log(error));
  };

  const detectScrollToBottom = () => {
    if (
      commentsRef.current.scrollTop >=
      commentsRef.current.scrollHeight - commentsRef.current.offsetHeight - 20
    ) {
      setThreadsPage((prevPage) => prevPage + 1);
    }
  };

  useEffect(() => {
    fetchThreads();
    if (commentsRef.current) {
      commentsRef.current.addEventListener('scroll', detectScrollToBottom);
    }
    return () => {
      if (commentsRef.current) {
        commentsRef.current.removeEventListener('scroll', detectScrollToBottom);
      }
    };
  }, []);

  useEffect(() => {
    if (show && textAreaRef.current) {
      textAreaRef.current.focus();
    }
  }, [show]);

  useEffect(() => {
    if (currentTimeStamp && Number.isInteger(currentTimeStamp)) {
      const highlightedThreadIndex = threads.findIndex(
        (thread) => thread.timeStamp === currentTimeStamp
      );

      setThreadsPage(Math.ceil(highlightedThreadIndex / 5));
    }
  }, [currentTimeStamp]);

  return (
    <div
      className={classnames(classes.Comments, show && classes.visible)}
      ref={commentsRef}
    >
      <header className={classes.header}>
        <h1>Comments</h1>
        <i onClick={onHide}>Close</i>
      </header>
      <main className={classes.commentsMain}>
        <div className={classes.addCommentBox}>
          <span className={classes.timeStamp}>
            {formatTimeStamp(currentTime, true)}
          </span>
          <textarea
            ref={textAreaRef}
            placeholder="create new thread"
            value={commentText}
            onChange={(event) => {
              setCommentText(event.target.value);
            }}
          />
          <i
            className={classes.sendComment}
            onClick={() => {
              sendComment(commentText);
              setCommentText('');
            }}
          >
            Send comment
          </i>
        </div>
        <div className={classes.threads}>
          {visibleThreads.map((thread) => (
            <Thread
              key={thread.id}
              time={thread.timeStamp}
              comments={thread.Comments}
              sendComment={sendComment}
              fetchThreads={fetchThreads}
              currentTimeStamp={currentTimeStamp}
              commentsRef={commentsRef}
            />
          ))}
        </div>
      </main>
    </div>
  );
}

export default connect(null, {
  createComment: actions.createComment,
})(Comments);
