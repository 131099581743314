import React, { useState, useEffect } from 'react';

import classnames from 'classnames';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';
import { PieChart } from 'react-minimal-pie-chart';

import classes from './styles.module.scss';

export default function GenderChart() {
  const [chartData, setChartData] = useState({ women: 50, men: 50 });

  useEffect(() => {
    const women = Math.floor(Math.random() * 100);
    const men = 100 - women;

    setChartData({ women, men });
  }, []);

  const renderTooltip = (props) => (
    <Tooltip className={classes.tooltip} {...props}>
      <div className={classes.tooltipContent}>
        <span className={classes.line}>
          <span className={classnames(classes.menColor, classes.color)} /> - men
          ({chartData.men}%)
        </span>
        <span className={classes.line}>
          <span className={classnames(classes.womenColor, classes.color)} /> -
          women ({chartData.women}%)
        </span>
      </div>
    </Tooltip>
  );

  return (
    <OverlayTrigger
      placement="top"
      delay={{ show: 250, hide: 400 }}
      overlay={renderTooltip}
    >
      <div className={classes.GenderChart}>
        <PieChart
          startAngle={35}
          data={[
            { title: 'women', value: chartData.women, color: '#9C27B0' },
            { title: 'men', value: chartData.men, color: '#2ECC71' },
          ]}
        />
        <div className={classes.details}>
          <span>
            {chartData.women}/{chartData.men}
          </span>
        </div>
        <h1>Gender</h1>
      </div>
    </OverlayTrigger>
  );
}
